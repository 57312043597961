import React, { useEffect, useState } from 'react';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../Utils/axiosInstance';
import Logger from '../../../../Utils/Logger';
import {
  ORDER_TICKET_PDF_ENDPOINT,
  ORDER_VIEW_ENDPOINT,
} from '../../../../Utils/Endpoints';
import { getModeText, getPriceFormatByType } from '../../../../Utils/common';

const OrderDetails = () => {
  const location = useLocation();
  const [orderDetail, setOrderDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const locationOrderDetail = location.state && location.state.order;

  useEffect(() => {
    if (locationOrderDetail?.id) {
      fetchOrder();
    }
  }, [locationOrderDetail]);

  const fetchOrder = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORDER_VIEW_ENDPOINT + `${locationOrderDetail?.id}`
      );
      if (response.data.code === 200) {
        setOrderDetail(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadTicket = async () => {
    try {
      setIsDownloadLoading(true);
      const response = await axiosInstance.get(
        ORDER_TICKET_PDF_ENDPOINT + `${locationOrderDetail?.id}`,
        { responseType: 'blob' }
      );

      if (response.status === 200) {
        // Create a new Blob object using the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob); // Create a URL for the Blob

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ticket_${locationOrderDetail?.id}.pdf`);

        // Append to the body
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsDownloadLoading(false);
    }
  };

  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            {/* html new add start */}
            {!isLoading && (
              <div className="edgtf-content">
                <div className="edgtf-content-inner">
                  <div className="edgtf-container edgtf-default-page-template">
                    <div
                      className="edgtf-container-inner clearfix"
                      style={{ paddingTop: '40px' }}
                    >
                      <div className="d-flex justify-content-between flex-wrap gap-3">
                        <h2 className="text-white m-0">
                          Order- <span>#{orderDetail?.order_number}</span>
                        </h2>
                        <button
                          type="button"
                          className="edgtf-btn edgtf-btn-medium edgtf-btn-solid edgtf-btn-orange-white"
                          onClick={downloadTicket}
                        >
                          <span className="edgtf-btn-text">
                            {isDownloadLoading
                              ? 'Loading...'
                              : 'Download Ticket'}
                          </span>
                        </button>
                      </div>
                      <p className="m-0 text-uppercase">
                        <span>{orderDetail?.created_at}</span>
                      </p>
                      <div className="edgtf-grid-row">
                        <div className="edgtf-page-content-holder edgtf-grid-col-12">
                          <div className="woocommerce">
                            <div className="details-user-new">
                              <div className="first-ddd">
                                <div className="upcoming-events">
                                  <h4
                                    className="mt-0"
                                    style={{ color: '#f45f0b' }}
                                  >
                                    Event Detail
                                  </h4>
                                  <p className="d-flex align-items-center gap-2 mb-2">
                                    <img
                                      src="../images/ticket.png"
                                      alt="location"
                                      style={{ width: '17px' }}
                                    />
                                    {orderDetail?.event?.name}
                                  </p>
                                  <p className="d-flex align-items-center gap-2 mt-0 mb-2 text-uppercase">
                                    <img
                                      src="../images/date.png"
                                      alt="date"
                                      style={{ width: '17px' }}
                                    />
                                    <span>
                                      {orderDetail?.event?.startDateTimeFormat}
                                    </span>{' '}
                                    &{' '}
                                    <span>
                                      {orderDetail?.event?.endDateTimeFormat}
                                    </span>
                                  </p>
                                  <p className="d-flex align-items-center gap-2 mt-0">
                                    <img
                                      src="../images/location.png"
                                      alt="location"
                                      style={{ width: '17px' }}
                                    />
                                    {
                                      orderDetail?.event?.location
                                        ?.addressString
                                    }
                                  </p>
                                </div>
                                <div className="payment-dd">
                                  <h4 className="" style={{ color: '#f45f0b' }}>
                                    Payment Detail
                                  </h4>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th
                                          colSpan="2"
                                          className="text-align-left "
                                        >
                                          <span
                                            className=""
                                            style={{ fontWeight: '500' }}
                                          >
                                            {' '}
                                            Payment Mode:
                                          </span>{' '}
                                          <span className="text-white">
                                            {getModeText(
                                              orderDetail?.transactions
                                            )}
                                          </span>
                                        </th>
                                        <th
                                          colSpan="2"
                                          className="text-align-right"
                                        >
                                          <p
                                            className={`badge ${
                                              orderDetail?.order_status
                                                ?.identifier === 'Completed'
                                                ? 'bg-success'
                                                : orderDetail?.order_status
                                                      ?.identifier ===
                                                    'Payment Pending'
                                                  ? 'bg-warning'
                                                  : 'bg-danger'
                                            } m-0`}
                                            style={{
                                              borderRadius: '0px',
                                              fontSize: '10px',
                                              textTransform: 'uppercase',
                                              display: 'inline-block',
                                              padding: '4px 10px',
                                              color: '#fff',
                                              transform: 'translateY(-1.4px)',
                                            }}
                                          >
                                            {
                                              orderDetail?.order_status
                                                ?.identifier
                                            }
                                          </p>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="t-b-r-a">
                                          <p>Fees</p>
                                          <h5>
                                            {getPriceFormatByType(
                                              orderDetail?.order_totals,
                                              'TOTAL_FEE_TO_SHOW'
                                            )
                                              ? getPriceFormatByType(
                                                  orderDetail?.order_totals,
                                                  'TOTAL_FEE_TO_SHOW'
                                                )
                                              : '$0.00'}
                                          </h5>
                                        </td>
                                        <td className="t-b-r-a">
                                          <p>Discount</p>
                                          <h5>
                                            {getPriceFormatByType(
                                              orderDetail?.order_totals,
                                              'DISCOUNT'
                                            )}
                                          </h5>
                                        </td>
                                        <td className="t-b-r-a">
                                          <p>SubTotal</p>
                                          <h5>
                                            {getPriceFormatByType(
                                              orderDetail?.order_totals,
                                              'BASE_TOTAL'
                                            )}
                                          </h5>
                                          <small
                                            style={{
                                              fontSize: '10px',
                                            }}
                                          >
                                            {orderDetail?.feeToSubTotalText && (
                                              <>
                                                (
                                                {orderDetail?.feeToSubTotalText}
                                                )
                                              </>
                                            )}
                                          </small>
                                        </td>
                                        <td className="t-b-r-a">
                                          <p>Total</p>
                                          <h5>
                                            {getPriceFormatByType(
                                              orderDetail?.order_totals,
                                              'TOTAL_AFTER_DISCOUNT'
                                            )}
                                          </h5>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    className="shop_table d-none shop_table_responsive cart woocommerce-cart-form__contents"
                                    cellSpacing="0"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="product-remove px-2">
                                          <span className="">Payment Mode</span>
                                        </th>
                                        <th
                                          className="product-remove px-2"
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        >
                                          <span className="">Fees</span>
                                        </th>
                                        <th
                                          className="product-thumbnail px-2"
                                          style={{
                                            textAlign: 'center',
                                          }}
                                        >
                                          <span className="">Discount</span>
                                        </th>
                                        <th className="product-price px-2 text-center">
                                          <span> Subtotal</span>
                                        </th>
                                        <th className="product-price px-2 text-center">
                                          <span>Total</span>
                                        </th>
                                        <th className="product-price px-2 text-end">
                                          <span>Status</span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="woocommerce-cart-form__cart-item cart_item t-b">
                                        <td className="product-remove">
                                          <span className="woocommerce-Price-amount ">
                                            Cash
                                          </span>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount amount">
                                            $ 2.00
                                          </span>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount amount">
                                            $ 3.00
                                          </span>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount amount">
                                            $ 5.00
                                          </span>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount amount">
                                            $ 5.00
                                          </span>
                                        </td>
                                        <td className="product-remove text-end">
                                          <p
                                            className="badge bg-success m-0"
                                            style={{
                                              borderRadius: '0px',
                                              fontSize: '10px',
                                              textTransform: 'uppercase',
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: 'block',
                                                marginTop: '1.1px',
                                                color: '#fff',
                                              }}
                                            >
                                              Paid
                                            </span>
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table
                                    className="shop_table d-none shop_table_responsive cart woocommerce-cart-form__contents"
                                    cellSpacing="0"
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          colSpan="2"
                                          className="product-remove px-2"
                                        >
                                          <span className="">
                                            Payment Mode: <span>Cash</span>
                                          </span>
                                        </th>
                                        <th
                                          colSpan="2"
                                          className="product-remove px-2"
                                          style={{
                                            textAlign: 'right',
                                          }}
                                        >
                                          <p
                                            className="badge bg-success m-0"
                                            style={{
                                              borderRadius: '0px',
                                              fontSize: '10px',
                                              textTransform: 'uppercase',
                                            }}
                                          >
                                            <span
                                              style={{
                                                display: 'block',
                                                marginTop: '1.1px',
                                                color: '#fff',
                                              }}
                                            >
                                              Paid
                                            </span>
                                          </p>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="woocommerce-cart-form__cart-item cart_item t-b">
                                        <td className="product-remove">
                                          <span className="woocommerce-Price-amount ">
                                            Fees
                                          </span>
                                          <h5 className="m-0 text-white">
                                            $ 2.00
                                          </h5>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount ">
                                            Discount
                                          </span>
                                          <h5 className="m-0 text-white">
                                            $ 1.00
                                          </h5>
                                        </td>
                                        <td className="product-remove text-center">
                                          <span className="woocommerce-Price-amount ">
                                            Subtotal
                                          </span>
                                          <h5 className="m-0 text-white">
                                            $ 5.00
                                          </h5>
                                        </td>
                                        <td className="product-remove text-end">
                                          <span className="woocommerce-Price-amount ">
                                            Total
                                          </span>
                                          <h5 className="m-0 text-white">
                                            $ 4.00
                                          </h5>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="ticket-pay">
                                <h4
                                  className="mt-0"
                                  style={{ color: '#f45f0b' }}
                                >
                                  Ticket Detail
                                </h4>
                                {orderDetail?.order_items &&
                                  orderDetail?.order_items?.length > 0 && (
                                    <table
                                      className="shop_table shop_table_responsive cart woocommerce-cart-form__contents"
                                      cellSpacing="0"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            className="product-remove px-2"
                                            style={{ width: '200px' }}
                                          >
                                            <span className="">
                                              Ticket Name
                                            </span>
                                          </th>
                                          <th
                                            className="product-remove px-2"
                                            style={{
                                              width: '200px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span className="">Quantity</span>
                                          </th>
                                          <th
                                            className="product-remove px-2"
                                            style={{
                                              width: '200px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <span className="">Price</span>
                                          </th>
                                          <th
                                            className="product-price px-2 text-center"
                                            style={{
                                              width: '200px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            Subtotal
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orderDetail?.order_items?.map(
                                          (item, index) => (
                                            <tr
                                              className="woocommerce-cart-form__cart-item cart_item t-b"
                                              key={index}
                                            >
                                              <td className="product-remove">
                                                <span className="woocommerce-Price-amount ">
                                                  {item?.holder?.name}
                                                </span>
                                              </td>
                                              <td className="product-remove text-center">
                                                <span className="woocommerce-Price-amount amount">
                                                  {item?.quantity}
                                                </span>
                                              </td>
                                              <td className="product-remove text-center">
                                                <span className="woocommerce-Price-amount amount">
                                                  {item?.holder?.priceFormat}
                                                </span>
                                              </td>
                                              <td className="product-remove text-center">
                                                <span className="woocommerce-Price-amount amount">
                                                  {getPriceFormatByType(
                                                    item?.order_totals,
                                                    'ITEM_BASE_TOTAL'
                                                  )}
                                                </span>
                                                <br />
                                                {item?.holder
                                                  ?.fee_to_customer === 0 &&
                                                  '( Service Fee Included )'}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                                {orderDetail?.qr && (
                                  <div className="bar-code-image d-none ">
                                    <img src={orderDetail?.qr} alt="barcode" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
