import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(currentPage);

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
    setPages(pageNumbers);
  }, [totalItems, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    onPageChange(pageNumber);
  };

  const handlePreviousPage = () => {
    if (activePage > 1) {
      handlePageChange(activePage - 1);
    }
  };

  const handleNextPage = () => {
    if (activePage < pages.length) {
      handlePageChange(activePage + 1);
    }
  };
  const renderPageNumbers = () => {
    return pages.map((pageNumber) => (
      <li key={pageNumber} className="page-item">
        <a
          className={`page-link ${activePage === pageNumber ? 'page-active' : ''}`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </a>
      </li>
    ));
  };

  return (
    <>
      <nav aria-label="..." className="mt-3 ">
        <ul className="pagination d-flex justify-content-end">
          <li className={`page-item ${activePage === 1 ? 'disabled' : ''}`}>
            <a className="page-link" onClick={handlePreviousPage}>
              Previous
            </a>
          </li>
          {renderPageNumbers()}
          <li
            className={`page-item ${activePage === pages.length ? 'disabled' : ''}`}
          >
            <a className="page-link" onClick={handleNextPage}>
              Next
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.any,
  onPageChange: PropTypes.func,
};

export default Pagination;
