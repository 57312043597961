import React, { useEffect, useState } from 'react';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';
import Logger from '../../../../Utils/Logger';
import axiosInstance from '../../../../Utils/axiosInstance';
import { ORDER_LIST_ENDPOINT } from '../../../../Utils/Endpoints';
import moment from 'moment/moment';
import Pagination from '../../../Components/Pagination';
import { getPriceFormatByType } from '../../../../Utils/common';
import OrderDetails from './Components/OrderDetails';
import NotFoundPage from '../../../Components/NotFoundPage';
import { Link } from 'react-router-dom';

const MyOrder = () => {
  const [upcomingOrderList, setUpcomingOrderList] = useState([]);
  const [pastOrderList, setPastOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  // Upcoming Pagination
  const [itemsPerPageUpcoming] = useState(10);
  const [totalItemsUpcoming, setTotalItemsUpcoming] = useState(0);
  const [currentPageUpcoming, setCurrentPageUpcoming] = useState(1);

  const handlePageChangeUpcoming = (pageNumber) => {
    setCurrentPageUpcoming(pageNumber);
  };

  // Past Pagination
  const [itemsPerPagePast] = useState(10);
  const [totalItemsPast, setTotalItemsPast] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(1);

  const handlePageChangePast = (pageNumber) => {
    setCurrentPagePast(pageNumber);
  };

  useEffect(() => {
    fetchUpcomingOrder();
  }, [currentPageUpcoming]);

  useEffect(() => {
    fetchPastOrder();
  }, [currentPagePast]);

  const fetchUpcomingOrder = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORDER_LIST_ENDPOINT +
          `?event_from_date=${moment().unix()}&page=${currentPageUpcoming}&per_page=${itemsPerPageUpcoming}`
      );
      if (response.data.code === 200) {
        setUpcomingOrderList(response.data.data.data);
        setTotalItemsUpcoming(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPastOrder = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        ORDER_LIST_ENDPOINT +
          `?event_to_date=${moment().unix()}&page=${currentPagePast}&per_page=${itemsPerPagePast}`
      );
      if (response.data.code === 200) {
        setPastOrderList(response.data.data.data);
        setTotalItemsPast(response.data.data.total);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOrder = (data) => {
    setSelectedOrder(data);
  };

  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}

            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <h2 className="text-white">My Order</h2>

                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>
                          <form
                            className="woocommerce-cart-form"
                            action="https://offbeat.qodeinteractive.com/cart/"
                            method="post"
                          >
                            <h4
                              id="order_review_heading"
                              className=""
                              style={{ color: '#f45f0b' }}
                            >
                              UpComing
                            </h4>
                            {!isLoading && upcomingOrderList?.length === 0 && (
                              <NotFoundPage title="UpComing Orders" />
                            )}
                            {!isLoading && upcomingOrderList?.length > 0 && (
                              <>
                                <div className="table-responsive">
                                  <table
                                    className="shop_table shop_table_responsive cart woocommerce-cart-form__contents order-list-table"
                                    cellSpacing="0"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="product-remove px-2">
                                          <span>Order Number</span>
                                        </th>
                                        <th className="product-remove px-2">
                                          <span>Name</span>
                                        </th>
                                        <th className="product-thumbnail px-2 text-center">
                                          <span className="">Date & Time</span>
                                        </th>
                                        <th className="product-price px-2 text-center">
                                          Subtotal
                                        </th>
                                        <th className="product-subtotal text-center px-2 ">
                                          Total
                                        </th>
                                        <th className="product-name px-2 text-end">
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {upcomingOrderList?.map((item, key) => (
                                        <tr
                                          className="woocommerce-cart-form__cart-item cart_item t-b"
                                          key={key}
                                        >
                                          <td className="product-remove c-s-a">
                                            <span className="woocommerce-Price-amount ">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s ">
                                                  <Link
                                                    to="/order-details"
                                                    state={{
                                                      order: item,
                                                    }}
                                                    className="order-nn"
                                                    onClick={() =>
                                                      handleOrder(item)
                                                    }
                                                  >
                                                    {item?.order_number}
                                                  </Link>
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td className="product-remove ">
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {item?.event?.name}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td className="product-thumbnail text-center ps-2">
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s text-uppercase">
                                                  {item?.created_at}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-name text-center px-2"
                                            data-title="Product"
                                          >
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {getPriceFormatByType(
                                                    item?.order_totals,
                                                    'BASE_TOTAL'
                                                  )}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-subtotal text-center px-2"
                                            data-title="Subtotal"
                                          >
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s text-white">
                                                  {getPriceFormatByType(
                                                    item?.order_totals,
                                                    'TOTAL_AFTER_DISCOUNT'
                                                  )}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-name text-end px-2"
                                            data-title="Product"
                                          >
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {
                                                    item?.order_status
                                                      ?.identifier
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                {totalItemsUpcoming > 10 && (
                                  <Pagination
                                    itemsPerPage={itemsPerPageUpcoming}
                                    totalItems={totalItemsUpcoming}
                                    currentPage={currentPageUpcoming}
                                    onPageChange={handlePageChangeUpcoming}
                                  />
                                )}
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="edgtf-grid-row mt-5">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>
                          <form
                            className="woocommerce-cart-form"
                            action="#"
                            method="post"
                          >
                            <h4
                              id="order_review_heading"
                              className=""
                              style={{ color: '#f45f0b' }}
                            >
                              Past Order
                            </h4>
                            {!isLoading && pastOrderList?.length === 0 && (
                              <NotFoundPage title="Past Orders" />
                            )}
                            {!isLoading && pastOrderList?.length > 0 && (
                              <>
                                <div className="table-responsive">
                                  <table
                                    className="shop_table shop_table_responsive cart woocommerce-cart-form__contents order-list-table"
                                    cellSpacing="0"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="product-remove px-2">
                                          <span>Order Number</span>
                                        </th>
                                        <th className="product-remove px-2 text-start">
                                          <span>Name</span>
                                        </th>
                                        <th className="product-thumbnail px-2 text-center">
                                          <span>Date & Time</span>
                                        </th>
                                        <th className="product-price px-2 text-center">
                                          Subtotal
                                        </th>
                                        <th className="product-subtotal text-center px-2 ">
                                          Total
                                        </th>
                                        <th className="product-name px-2 text-end">
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {pastOrderList?.map((item, key) => (
                                        <tr
                                          className="woocommerce-cart-form__cart-item cart_item t-b"
                                          key={key}
                                        >
                                          <td className="product-remove c-s-a">
                                            <span className="woocommerce-Price-amount ">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  <Link
                                                    to="/order-details"
                                                    state={{
                                                      order: item,
                                                    }}
                                                    style={{
                                                      textDecoration:
                                                        'underline',
                                                    }}
                                                    className="order-nn"
                                                  >
                                                    {item?.order_number}
                                                  </Link>
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td className="product-remove">
                                            <span className="woocommerce-Price-amount amount text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {item?.event?.name}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td className="product-thumbnail text-center ps-2">
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s text-uppercase">
                                                  {item?.created_at}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-name text-center"
                                            data-title="Product"
                                          >
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {getPriceFormatByType(
                                                    item?.order_totals,
                                                    'BASE_TOTAL'
                                                  )}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-subtotal text-center px-2"
                                            data-title="Subtotal"
                                          >
                                            <span className="woocommerce-Price-amount amount">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s text-white">
                                                  {getPriceFormatByType(
                                                    item?.order_totals,
                                                    'TOTAL_AFTER_DISCOUNT'
                                                  )}
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                          <td
                                            className="product-name text-end"
                                            data-title="Product"
                                          >
                                            <span className="woocommerce-Price-amount amount  text-white">
                                              <bdi>
                                                <span className="woocommerce-Price-currencySymbol c-s">
                                                  {
                                                    item?.order_status
                                                      ?.identifier
                                                  }
                                                </span>
                                              </bdi>
                                            </span>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                {totalItemsPast > 10 && (
                                  <Pagination
                                    itemsPerPage={itemsPerPagePast}
                                    totalItems={totalItemsPast}
                                    currentPage={currentPagePast}
                                    onPageChange={handlePageChangePast}
                                  />
                                )}
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>
                          <form
                            className="woocommerce-cart-form"
                            action="https://offbeat.qodeinteractive.com/cart/"
                            method="post"
                          ></form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
      {Object.keys(selectedOrder)?.length > 0 && (
        <OrderDetails order={selectedOrder} />
      )}
    </>
  );
};

export default MyOrder;
