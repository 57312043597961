import { useState } from 'react';
import './Signup.css';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  SIGNUP_STEP_ONE_ENDPOINT,
  SIGNUP_STEP_TWO_ENDPOINT,
} from '../../../../Utils/Endpoints';
import { saveObject, setLoginCustomer } from '../../../../Utils/LocalStorage';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';

// Validation schema
const SignUpStepOneSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required.'),
});

const Signup = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [succMsg, setSuccMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const validate = () => {
    try {
      setGeneralError('');
      SignUpStepOneSchema.validateSync({ email }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmitOne = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(SIGNUP_STEP_ONE_ENDPOINT, {
        email,
      });
      if (response.data.code === 200) {
        setToken(response?.data?.data?.confirmation_token);
        setOtp(response?.data?.data?.code);

        setSuccMsg(response.data.message);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitTwo = async (e) => {
    e.preventDefault();
    setGeneralError('');
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(SIGNUP_STEP_TWO_ENDPOINT, {
        email,
        confirmation_token: token,
        code: otp,
      });
      if (response.data.code === 200) {
        const { token, user } = response.data.data;
        saveObject('customerToken', token);
        setLoginCustomer(user);
        setSuccMsg(response.data.message);
        setTimeout(() => {
          setSuccMsg('');
          handleNavigation('/profile-update');
        }, 1000);
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="sign-up d-none">
        <img className="image" alt="Image" src="/images/background.png" />
        <div className="left">
          <div className="back" onClick={() => handleNavigation('/')}>
            <img className="vector" alt="Vector" src="/images/vector.png" />
            <div className="text-wrapper">Back to website</div>
          </div>
          <div className="logo">
            <img className="img" alt="Logo" src="/images/logo.png" />
          </div>
        </div>
        <div className="right">
          <div className="form-outer">
            <h2 className="main-heading">Sign up</h2>
            {!token && <p className="p">Please fill out your details</p>}

            {token === '' && (
              <form className="submit-form" onSubmit={handleSubmitOne}>
                <div className="form">
                  <input
                    className="input input-instance"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                  />
                  {errors?.email && <div className="error">{errors.email}</div>}
                  <div className="spacer" />

                  {generalError && <div className="error">{generalError}</div>}
                  <button
                    className={`button button-instance`}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        className="spinner"
                      >
                        <circle
                          className="path"
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="5"
                        />
                      </svg>
                    ) : (
                      <div className="continue">Continue</div>
                    )}
                  </button>
                </div>
              </form>
            )}

            {token && (
              <form className="submit-form" onSubmit={handleSubmitTwo}>
                {succMsg && (
                  <div className="signup-success mt-3">{succMsg}</div>
                )}
                <div className="form">
                  <input
                    className="input input-instance"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email"
                    autoComplete="off"
                    disabled
                  />
                  <input
                    className="input input-instance"
                    type="text"
                    maxLength={4}
                    name="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Please Enter OTP"
                    autoComplete="off"
                    required
                  />
                  {errors?.otp && <div className="error">{errors.otp}</div>}
                  <div className="spacer" />

                  {generalError && <div className="error">{generalError}</div>}
                  <button
                    className={`button button-instance`}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        className="spinner"
                      >
                        <circle
                          className="path"
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="5"
                        />
                      </svg>
                    ) : (
                      <div className="continue">Submit</div>
                    )}
                  </button>
                </div>
              </form>
            )}

            <div className="frame">
              <p className="new-here-create-an">
                <span className="span">Already have an account? </span>
                <span
                  className="text-wrapper-2"
                  onClick={() => handleNavigation('/customer')}
                >
                  Log in here
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper d-none">
        <div className="">
          <div className="row g-0 m-0">
            <div className="col-xl-6 col-lg-12">
              <div className="login-cover-wrapper">
                <div className="logo">
                  <img
                    src="../images/logo-icon-2.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                  <h4 className="logo-text text-warning">PARTYPASS</h4>
                </div>
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <h4>Sign Up</h4>
                      <p>Create new account</p>
                    </div>
                    {token === '' && (
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitOne}
                      >
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                          />
                          {errors?.email && (
                            <div className="error">{errors.email}</div>
                          )}
                          <div className="spacer" />

                          {generalError && (
                            <div className="error">{generalError}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p
                            className="mb-0"
                            onClick={() => handleNavigation('/login')}
                          >
                            Already have an account?
                            <Link to="/login"> Login</Link>
                          </p>
                        </div>
                      </form>
                    )}
                    {token && (
                      <form
                        className="form-body row g-3"
                        onSubmit={handleSubmitTwo}
                      >
                        {succMsg && (
                          <div className="col-12 text-success">{succMsg}</div>
                        )}
                        <div className="col-12">
                          <label htmlFor="inputEmail" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail"
                            value={email}
                            placeholder="Email"
                            disabled
                            autoComplete="off"
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="inputOtp" className="form-label">
                            Enter OTP
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputOtp"
                            maxLength={4}
                            name="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Please Enter OTP"
                            autoComplete="off"
                            required
                          />
                          {errors?.otp && (
                            <div className="error">{errors.otp}</div>
                          )}
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              Create Account
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="position-fixed top-0 h-100 d-xl-block d-none login-cover-img"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* new html start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-3">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="pb-4 ">
                            <h2 className="mb-0">Sign Up</h2>
                            <p className="m-0">Create new account</p>
                          </div>
                          {token === '' && (
                            <form
                              className="form-body row g-3"
                              onSubmit={handleSubmitOne}
                            >
                              <div className="col-12">
                                <label
                                  htmlFor="inputEmail"
                                  className="form-label"
                                >
                                  Email Address
                                </label>
                                <input
                                  type="email"
                                  className="woocommerce-Input woocommerce-Input--text input-text text-white"
                                  id="inputEmail"
                                  value={email}
                                  placeholder="Email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  autoComplete="off"
                                />
                                {errors?.email && (
                                  <div className="error">{errors.email}</div>
                                )}
                                <div className="spacer" />

                                {generalError && (
                                  <div className="error">{generalError}</div>
                                )}
                              </div>
                              <div className="col-12">
                                <div className="">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? 'Loading...' : 'Continue'}
                                  </button>
                                </div>
                              </div>
                              <div className="col-12">
                                <p
                                  className="m-0"
                                  onClick={() => handleNavigation('/login')}
                                >
                                  Already have an account?
                                  <Link
                                    to="/login"
                                    className="text-decoration-underline"
                                  >
                                    {' '}
                                    Login
                                  </Link>
                                </p>
                              </div>
                            </form>
                          )}
                          {token && (
                            <form
                              className="form-body row g-3"
                              onSubmit={handleSubmitTwo}
                            >
                              {succMsg && (
                                <div className="col-12 text-success">
                                  {succMsg}
                                </div>
                              )}
                              <div className="col-12">
                                <label
                                  htmlFor="inputEmail"
                                  className="form-label"
                                >
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="woocommerce-Input woocommerce-Input--text input-text text-white"
                                  id="inputEmail"
                                  value={email}
                                  placeholder="Email"
                                  disabled
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-12">
                                <label
                                  htmlFor="inputOtp"
                                  className="form-label"
                                >
                                  Enter OTP
                                </label>
                                <input
                                  type="text"
                                  className="woocommerce-Input woocommerce-Input--text input-text text-white"
                                  id="inputOtp"
                                  maxLength={4}
                                  name="otp"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                  placeholder="Please Enter OTP"
                                  autoComplete="off"
                                  required
                                />
                                {errors?.otp && (
                                  <div className="error">{errors.otp}</div>
                                )}
                              </div>
                              <div className="col-12">
                                <div className="">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isLoading}
                                  >
                                    {isLoading
                                      ? 'Loading...'
                                      : 'Create Account'}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* new html end */}
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
