import { useNavigate } from 'react-router-dom';
import { getLoginCustomer, logoutCustomer } from '../../../Utils/LocalStorage';
import { useEffect } from 'react';

const Home = () => {
  const navigate = useNavigate();
  const user = getLoginCustomer();

  useEffect(() => {
    if (
      user &&
      !user?.first_name &&
      window.location.pathname !== '/profile-update'
    ) {
      navigate('/profile-update');
    }
  }, [user]);

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleSignOut = async () => {
    await logoutCustomer();
    await handleNavigation('/');
  };

  return (
    <div className="App App-header">
      <p className="text-capitalize fs-5 fw-bolder">{user?.name}</p>
      <button type="button" onClick={handleSignOut}>
        Sign Out
      </button>
    </div>
  );
};

export default Home;
