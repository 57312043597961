import React from 'react';
import PropTypes from 'prop-types';

const NotFoundPage = ({ title }) => {
  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            {/* html new add start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>
                          <div className="wc-empty-cart-message">
                            <div className="cart-empty woocommerce-info text-white">
                              No {title} Found
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* html new add end */}
          </div>
        </div>
      </div>
    </>
  );
};

NotFoundPage.propTypes = {
  title: PropTypes.string,
};
export default NotFoundPage;
