import { useState } from 'react';
// import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { LOGIN_ENDPOINT } from '../../../../Utils/Endpoints';
import axiosInstance from '../../../../Utils/axiosInstance';
import { saveObject, setLoginCustomer } from '../../../../Utils/LocalStorage';
import Header from '../../Home/Components/Header';
import Footer from '../../Home/Components/Footer';

// Validation schema
const LogInSchema = Yup.object().shape({
  email: Yup.string().email().required('Enter valid email'),
  password: Yup.string().min(8).required('Enter valid password'),
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(url);
  };

  const validate = () => {
    try {
      setGeneralError('');
      LogInSchema.validateSync({ email, password }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors = {};
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      setErrors(validationErrors);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(LOGIN_ENDPOINT, {
        email,
        password,
      });
      if (response.data.code === 200) {
        const { token, user } = response.data.data;
        saveObject('customerToken', token);
        setLoginCustomer(user);
        handleNavigation('/');
      } else {
        setGeneralError(response.data.message);
      }
    } catch (error) {
      setGeneralError(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="log-in d-none">
        <img className="image" src="/images/background.png" alt="Image" />
        <div className="left">
          <div className="back" onClick={() => handleNavigation('/')}>
            <img className="vector" alt="Vector" src="/images/vector.png" />
            <div className="text-wrapper">Back to website</div>
          </div>
          <div className="logo">
            <img className="img" alt="Logo" src="/images/logo.png" />
          </div>
        </div>
        <div className="right">
          <div className="form-outer">
            <h2 className="main-heading">Login</h2>
            <p className="p">Please fill out your details</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form">
                <input
                  className="input input-instance"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="off"
                />
                {errors.email && <div className="error">{errors.email}</div>}
                <input
                  className="input input-instance"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  autoComplete="off"
                />
                {errors.password && (
                  <div className="error">{errors.password}</div>
                )}

                <div className="spacer" />
                {generalError && <div className="error">{generalError}</div>}

                <button
                  className={`button button-instance`}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 50 50"
                      className="spinner"
                    >
                      <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                      />
                    </svg>
                  ) : (
                    <div className="continue">Continue</div>
                  )}
                </button>
              </div>
            </form>
            <div className="frame">
              <p className="new-here-create-an">
                <span className="span">New here? </span>
                <span
                  className="text-wrapper-2"
                  onClick={() => handleNavigation('/signup')}
                >
                  Create an account
                </span>
              </p>
              <p className="forgot-your-password">
                <span className="span">Forgot your password? </span>
                <span
                  className="text-wrapper-2"
                  // onClick={() => handleNavigation('/auth/forget-password')}
                >
                  Click here
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper d-none">
        <div className="">
          <div className="row g-0 m-0">
            <div className="col-xl-6 col-lg-12">
              <div className="login-cover-wrapper">
                <div className="logo">
                  <img
                    src="images/logo-icon-2.png"
                    className="logo-icon"
                    alt="logo icon"
                  />
                  <h4 className="logo-text text-warning">PARTYPASS</h4>
                </div>
                <div className="card shadow-none">
                  <div className="card-body">
                    <div className="text-center">
                      <h4>Sign In</h4>
                      <p>Sign In to your account</p>
                    </div>
                    <form
                      className="form-body row g-3 login-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="col-12">
                        <label htmlFor="inputEmail" className="form-label">
                          Email
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          id="inputEmail"
                          name="email"
                          value={email}
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          autoComplete="off"
                        />
                        {errors.email && (
                          <div className="error">{errors.email}</div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="inputPassword" className="form-label">
                          Password
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          id="inputPassword"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          autoComplete="off"
                        />
                        {errors.password && (
                          <div className="error">{errors.password}</div>
                        )}
                        {generalError && (
                          <div className="error">{generalError}</div>
                        )}
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckRemember"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckRemember"
                          >
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 text-end">
                        <Link to="/forgot">Forgot Password?</Link>
                      </div>
                      <div className="col-12 col-lg-12">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isLoading}
                          >
                            Sign In
                          </button>
                        </div>
                      </div>

                      <div className="col-12 col-lg-12 text-center">
                        <p className="mb-0">
                          Don&apos;t have an account?
                          <Link to="/signup"> Sign up</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12">
              <div className="position-fixed top-0 h-100 d-xl-block d-none login-cover-img"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="edgtf-woocommerce-page edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-3">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>

                          <h2 className="">Login</h2>

                          <form
                            className="woocommerce-form woocommerce-form-login login"
                            method="post"
                            data-gtm-form-interact-id="0"
                            onSubmit={handleSubmit}
                          >
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide my-1">
                              <label htmlFor="username">
                                Email Address&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                className="woocommerce-Input woocommerce-Input--text input-text text-white"
                                defaultValue=""
                                type="email"
                                id="inputEmail"
                                name="email"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                              />
                              {errors.email && (
                                <div className="error">{errors.email}</div>
                              )}
                            </p>
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide my-1">
                              <label htmlFor="password">
                                Password&nbsp;
                                <span className="required">*</span>
                              </label>
                              <span className="password-input">
                                <input
                                  className="woocommerce-Input woocommerce-Input--text input-text text-white"
                                  type="password"
                                  id="inputPassword"
                                  name="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  placeholder="Password"
                                  autoComplete="off"
                                />
                                {errors.password && (
                                  <div className="error">{errors.password}</div>
                                )}
                                {generalError && (
                                  <div className="error">{generalError}</div>
                                )}
                                <span className="show-password-input"></span>
                              </span>
                            </p>

                            <div className="form-row">
                              <div className="d-flex align-items-start justify-content-between">
                                <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                  <input
                                    className="woocommerce-form__input woocommerce-form__input-checkbox"
                                    name="rememberme"
                                    type="checkbox"
                                    id="rememberme"
                                    value="forever"
                                    data-gtm-form-interact-field-id="0"
                                  />
                                  <span className="ps-1">Remember me</span>
                                </label>
                                <p className="woocommerce-LostPassword lost_password m-0">
                                  <Link
                                    to="/forgot"
                                    className="text-decoration-underline"
                                  >
                                    Lost your password?
                                  </Link>
                                </p>
                              </div>
                              <input
                                type="hidden"
                                id="woocommerce-login-nonce"
                                name="woocommerce-login-nonce"
                                value="f0ec9a5b29"
                              />
                              <input
                                type="hidden"
                                name="_wp_http_referer"
                                value="/my-account/"
                              />
                              <button
                                type="submit"
                                className="woocommerce-button button woocommerce-form-login__submit mt-3"
                                name="login"
                                value="Log in"
                                disabled={isLoading}
                              >
                                {isLoading ? 'Loading...' : 'Log in'}
                              </button>
                              <p>
                                Don&#39;t have an account? {''}
                                <Link
                                  to="/signup"
                                  className="text-decoration-underline"
                                >
                                  Sign up
                                </Link>
                              </p>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
