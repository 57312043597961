import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  EVENT_TICKET_ENDPOINT,
  SAVE_CART_ENDPOINT,
} from '../../../../Utils/Endpoints';
import Logger from '../../../../Utils/Logger';
import { TICKET_CLASS } from '../../../../Utils/ModelClasses';
import Toast from '../../../../Utils/Toast';
import MapComponent from './MapComponent';
import PropTypes from 'prop-types';
import { encrypt, getEventSettingData } from '../../../../Utils/common';
import FollowShare from './FollowShare';
import { getLoginCustomer } from '../../../../Utils/LocalStorage';
import NotFound from '../../../../Components/NotFound';

const EventPreview = ({ type, eventId }) => {
  const location = useLocation();
  const [ticketList, setTicketList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setIsCartLoading] = useState(false);
  const eventLocationData = location.state && location.state.event;
  const [eventObject, setEventObject] = useState({});
  const [quantities, setQuantities] = useState({});
  const [generalError, setGeneralError] = useState('');
  const [ownerIds, setOwnerIds] = useState({}); // New state for owner IDs
  const navigate = useNavigate();
  const customer = getLoginCustomer();

  useEffect(() => {
    if (eventId || eventLocationData?.id) {
      fetchEvent();
      fetchTicket();
    }
  }, [eventLocationData]);

  const fetchEvent = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT + `${eventId ? eventId : eventLocationData?.id}`
      );
      if (response.data.code === 200) {
        setEventObject(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicket = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        EVENT_TICKET_ENDPOINT +
          `${eventId ? eventId : eventLocationData?.id}/tickets`
      );
      if (response.data.code === 200) {
        setTicketList(response.data.data);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIncrement = (index, item) => {
    setGeneralError('');
    setQuantities((prev) => {
      const newQuantity = (prev[index] || 0) + 1;
      if (newQuantity > item?.maximum_order_qty) return prev; // Check maximum order quantity
      return {
        ...prev,
        [index]: newQuantity,
      };
    });

    // Save the ticket ID as owner_id
    setOwnerIds((prev) => ({
      ...prev,
      [index]: item.id,
    }));
  };

  const handleDecrement = (index, item) => {
    setGeneralError('');
    setQuantities((prev) => {
      const newQuantity = (prev[index] || 0) - 1;
      if (newQuantity < item?.minimum_order_qty) return prev; // Check minimum order quantity
      return {
        ...prev,
        [index]: newQuantity,
      };
    });
  };
  const createTicketArray = () => {
    const ticketArray = [];

    Object.keys(quantities).forEach((index) => {
      const quantity = quantities[index];
      const ownerId = ownerIds[index];

      if (quantity > 0 && ownerId) {
        // Only include if quantity is greater than 0
        ticketArray.push({
          owner_class: TICKET_CLASS,
          owner_id: ownerId,
          quantity: quantity,
        });
      }
    });

    return ticketArray;
  };

  const handleSaveToCart = async (e) => {
    e.preventDefault();
    if (customer?.id) {
      setGeneralError('');
      setIsCartLoading(true);
      try {
        let requestObject = {
          items: createTicketArray(),
        };

        const response = await axiosInstance.post(
          SAVE_CART_ENDPOINT,
          requestObject
        );
        if (response.data.code === 200) {
          navigate('/cart');
          Toast.success(response.data.message, {
            autoClose: 2000,
          });
        } else {
          setGeneralError(response.data.message);
        }
      } catch (error) {
        setGeneralError(error.response?.data?.message);
      } finally {
        setIsCartLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const isButtonDisabled = createTicketArray().length === 0 || isCartLoading;

  return (
    <>
      {eventObject && (
        <div className="edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
          <div className="edgtf-wrapper">
            <div className="edgtf-wrapper-inner">
              <Header />
              {/* new html start */}
              <div className="edgtf-content">
                {!isLoading &&
                  eventObject &&
                  Object.keys(eventObject)?.length > 0 && (
                    <div className="edgtf-content-inner">
                      <div
                        className="edgtf-title-holder edgtf-standard-type edgtf-title-va-header-bottom"
                        style={{
                          backgroundColor: '#000',
                          paddingTop: '70px',
                          paddingBottom: '70px',
                        }}
                        data-height="150"
                      >
                        <div className="edgtf-title-wrapper">
                          <div className="edgtf-title-inner">
                            <div className="edgtf-grid">
                              <p
                                className="edgtf-page-subtitle text-capitalize"
                                style={{ color: '#f45f0b' }}
                              >
                                {eventObject?.organization?.name}
                              </p>
                              <h2 className="edgtf-page-title entry-title">
                                {eventObject?.name}
                                <span
                                  className="edgtf-page-title-suffix"
                                  style={{ color: '#f45f0b' }}
                                ></span>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="offbeat-event-single-holder">
                        <div className="edgtf-full-width">
                          <div className="edgtf-full-width-inner pt-0">
                            <div className="edgtf-grid p-l-r">
                              <div className="offbeat-event-top-holder edgtf-grid-row edgtf-grid-huge-gutter preview-tt">
                                <div className="offbeat-event-top-right edgtf-grid-col-4 off-beat mb-5 p-l-r-2">
                                  <div className="offbeat-event-featured-image pt-1">
                                    <img
                                      width="800"
                                      height="943"
                                      src={
                                        eventObject?.banner?.fullUrl
                                          ? eventObject?.banner?.fullUrl
                                          : '../images/thumbnail.png'
                                      }
                                      className="attachment-full size-full wp-post-image hh-img"
                                      alt="efqw"
                                      decoding="async"
                                      fetchPriority="high"
                                      sizes="(max-width: 800px) 100vw, 800px"
                                    />
                                  </div>
                                  <div className="user-ticket-details">
                                    <h4 className="offbeat-event-info-title  pt-0">
                                      Details
                                    </h4>
                                    <div className="offbeat-event-info-item offbeat-event-date">
                                      <span className="offbeat-event-info-item-title">
                                        Start:
                                      </span>
                                      <span className="offbeat-event-info-item-desc text-uppercase">
                                        {eventObject?.startDateTimeFormat}
                                      </span>
                                    </div>
                                    <div className="offbeat-event-info-item offbeat-event-date">
                                      <span className="offbeat-event-info-item-title">
                                        End:
                                      </span>
                                      <span className="offbeat-event-info-item-desc text-uppercase">
                                        {eventObject?.endDateTimeFormat}
                                      </span>
                                    </div>
                                    <div className="offbeat-event-info-item offbeat-event-categories">
                                      <span className="offbeat-event-info-item-title">
                                        Address:
                                      </span>
                                      <span className="offbeat-event-info-item-desc">
                                        {eventObject?.location?.addressString}
                                      </span>
                                    </div>
                                    {eventObject?.legal_settings &&
                                      getEventSettingData(
                                        eventObject?.legal_settings,
                                        'AGE_RESTRICTION'
                                      ) && (
                                        <div className="offbeat-event-info-item offbeat-event-categories">
                                          <span className="offbeat-event-info-item-title">
                                            Age Limit:
                                          </span>

                                          <span className="offbeat-event-info-item-desc">
                                            {getEventSettingData(
                                              eventObject?.legal_settings,
                                              'AGE_RESTRICTION'
                                            )}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                  <FollowShare
                                    shareList={eventObject?.socialMedia}
                                  />
                                </div>
                                <div className="offbeat-event-top-left edgtf-grid-col-8 mb-5">
                                  <div className="offbeat-event-content-wrap clearfix pb-5 pt-4 pt-xl-0">
                                    <div className="offbeat-event-text-main content-center">
                                      <div className="offbeat-event-content">
                                        <div className="wpb-content-wrapper">
                                          <div className="vc_row wpb_row vc_row-fluid vc_custom_1519891401815">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                              <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                  <div className="edgtf-google-map-holder">
                                                    <div
                                                      className="edgtf-google-map"
                                                      style={{
                                                        minHeight: '350px',
                                                        maxHeight: '350px',
                                                        marginBottom: '41px',
                                                      }}
                                                    >
                                                      <MapComponent
                                                        lat={
                                                          eventObject?.location
                                                            ?.lat
                                                        }
                                                        lng={
                                                          eventObject?.location
                                                            ?.lng
                                                        }
                                                        city={
                                                          eventObject?.location_type ===
                                                          'CITY'
                                                            ? eventObject
                                                                ?.location
                                                                ?.addressString
                                                            : null
                                                        }
                                                      />
                                                    </div>
                                                    <div className="edgtf-google-map-overlay"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="vc_row wpb_row vc_row-fluid d-none">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                              <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                  <div
                                                    className="vc_empty_space"
                                                    style={{ height: '53px' }}
                                                  >
                                                    <span className="vc_empty_space_inner"></span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            className="vc_row wpb_row vc_row-fluid"
                                            style={{ paddingTop: '0px' }}
                                          >
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                              <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                  <div className="wpb_text_column wpb_content_element">
                                                    <div className="wpb_wrapper">
                                                      <h4 className="mt-0">
                                                        About Tour
                                                      </h4>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="vc_empty_space"
                                                    style={{ height: '6px' }}
                                                  >
                                                    <span className="vc_empty_space_inner"></span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="vc_row wpb_row vc_row-fluid">
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                              <div className="vc_column-inner">
                                                <div className="wpb_wrapper">
                                                  <div className="wpb_text_column wpb_content_element">
                                                    <div className="wpb_wrapper">
                                                      <p>
                                                        {
                                                          eventObject?.description
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="vc_row wpb_row vc_row-fluid"
                                            style={{ paddingTop: '0px' }}
                                          >
                                            <div className="wpb_column vc_column_container vc_col-sm-12">
                                              <div className="vc_column-inner ">
                                                <div className="wpb_wrapper">
                                                  <div className="wpb_text_column wpb_content_element">
                                                    <div className="wpb_wrapper">
                                                      {eventObject?.legal_settings &&
                                                        getEventSettingData(
                                                          eventObject?.legal_settings,
                                                          'GENERAL_TERMS'
                                                        ) && (
                                                          <>
                                                            <h6 className="mt-4 mb-2">
                                                              General Conditions
                                                            </h6>

                                                            <div className="vc_row wpb_row vc_row-fluid">
                                                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                  <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element">
                                                                      <div className="wpb_wrapper">
                                                                        <p
                                                                          className="mt-0"
                                                                          style={{
                                                                            color:
                                                                              '#818181',
                                                                          }}
                                                                        >
                                                                          {getEventSettingData(
                                                                            eventObject?.legal_settings,
                                                                            'GENERAL_TERMS'
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      {eventObject?.legal_settings &&
                                                        getEventSettingData(
                                                          eventObject?.legal_settings,
                                                          'REFUND_POLICY'
                                                        ) && (
                                                          <>
                                                            <h6 className="mt-4 mb-2">
                                                              Refund Policy
                                                            </h6>
                                                            <div className="vc_row wpb_row vc_row-fluid">
                                                              <div className="wpb_column vc_column_container vc_col-sm-12">
                                                                <div className="vc_column-inner">
                                                                  <div className="wpb_wrapper">
                                                                    <div className="wpb_text_column wpb_content_element">
                                                                      <div className="wpb_wrapper">
                                                                        <p
                                                                          className="mt-0"
                                                                          style={{
                                                                            color:
                                                                              '#818181',
                                                                          }}
                                                                        >
                                                                          {getEventSettingData(
                                                                            eventObject?.legal_settings,
                                                                            'REFUND_POLICY'
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className="vc_empty_space"
                                                    style={{ height: '6px' }}
                                                  >
                                                    <span className="vc_empty_space_inner"></span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="offbeat-event-social content-center d-none">
                                      <div className="edgtf-social-share-holder edgtf-list">
                                        <h4 className="edgtf-social-title">
                                          Follow and Share
                                        </h4>
                                        <ul>
                                          <li className="edgtf-facebook-share">
                                            <Link
                                              itemProp="url"
                                              className="edgtf-share-link"
                                              to="#"
                                              onClick="window.open('https://www.facebook.com/sharer.php?u=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F', 'sharer', 'toolbar=0,status=0,width=620,height=280');"
                                            >
                                              <span className="edgtf-social-network-icon social_facebook_circle"></span>
                                            </Link>
                                          </li>
                                          <li className="edgtf-twitter-share">
                                            <Link
                                              itemProp="url"
                                              className="edgtf-share-link"
                                              to="#"
                                              onClick="window.open('https://twitter.com/intent/tweet?text=https://offbeat.qodeinteractive.com/event/greenspan/', 'popupwindow', 'scrollbars=yes,width=800,height=400');"
                                            >
                                              <span className="edgtf-social-network-icon social_twitter_circle"></span>
                                            </Link>
                                          </li>
                                          <li className="edgtf-linkedin-share">
                                            <Link
                                              itemProp="url"
                                              className="edgtf-share-link"
                                              to="#"
                                              onClick="popUp=window.open('https://www.linkedin.com/sharing/share-offsite?url==https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;title=Greenspan', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                            >
                                              <span className="edgtf-social-network-icon social_linkedin_circle"></span>
                                            </Link>
                                          </li>
                                          <li className="edgtf-tumblr-share">
                                            <Link
                                              itemProp="url"
                                              className="edgtf-share-link"
                                              to="#"
                                              onClick="popUp=window.open('https://www.tumblr.com/share/link?url=https%3A%2F%2Foffbeat.qodeinteractive.com%2Fevent%2Fgreenspan%2F&amp;name=Greenspan&amp;description=', 'popupwindow', 'scrollbars=yes,width=800,height=400');popUp.focus();return false;"
                                            >
                                              <span className="edgtf-social-network-icon social_tumblr_circle"></span>
                                            </Link>
                                          </li>{' '}
                                        </ul>
                                      </div>{' '}
                                    </div>
                                  </div>
                                </div>

                                {/* ticket new create */}
                                {!isLoading && ticketList?.length > 0 && (
                                  <div className="wpb_wrapper pb-5 p-lr-remove">
                                    <div className="offbeat-event-list-holder offbeat-event-list-standard edgtf-light-skin edgtf-el-no-columns edgtf-normal-space">
                                      <div className="offbeat-event-list-holder-inner ">
                                        {ticketList?.map((item, index) => (
                                          <div
                                            className="edgtf-el-item content-center c-c"
                                            key={index}
                                          >
                                            <Link
                                              className="edgtf-el-item-link-outer d-none"
                                              to="#"
                                            ></Link>
                                            <div className="edgtf-el-item-date-holder c-p-q">
                                              <div className="edgtf-el-item-date d-items">
                                                <span className="edgtf-el-item-day pe-3">
                                                  {item?.priceFormat}
                                                </span>
                                              </div>
                                            </div>

                                            <div className="edgtf-el-item-title-holder ttt-cen">
                                              <h4 className="edgtf-el-item-title">
                                                {item?.name}
                                              </h4>
                                              <div className="ps-4 d-flex gap-2">
                                                <small>
                                                  <strong>Start Date: </strong>
                                                  {
                                                    item?.sellingStartDateTimeFormat
                                                  }
                                                </small>
                                                <small>
                                                  <strong>End Date: </strong>
                                                  {
                                                    item?.sellingEndDateTimeFormat
                                                  }
                                                </small>
                                              </div>
                                            </div>
                                            {item?.status === 'AVAILABLE' &&
                                              item?.can_purchase === true && (
                                                <div className="mms-a">
                                                  <div className="edgtf-el-item-link-holder buy-now">
                                                    <button
                                                      onClick={() =>
                                                        handleDecrement(
                                                          index,
                                                          item
                                                        )
                                                      }
                                                      className={
                                                        (quantities[index] ||
                                                          0) <=
                                                        item?.minimum_order_qty
                                                          ? 'dis-btn-count'
                                                          : 'active-btn-count'
                                                      }
                                                      disabled={
                                                        (quantities[index] ||
                                                          0) <=
                                                        item?.minimum_order_qty
                                                      }
                                                    >
                                                      -
                                                    </button>
                                                    <input
                                                      type="text"
                                                      style={{
                                                        padding: '12px 4px',
                                                        textAlign: 'center',
                                                      }}
                                                      value={
                                                        quantities[index] || 0
                                                      }
                                                      className="text-white "
                                                      readOnly
                                                    />
                                                    <button
                                                      onClick={() =>
                                                        handleIncrement(
                                                          index,
                                                          item
                                                        )
                                                      }
                                                      className={
                                                        (quantities[index] ||
                                                          0) >=
                                                        item?.maximum_order_qty
                                                          ? 'dis-btn-count'
                                                          : 'active-btn-count'
                                                      }
                                                      disabled={
                                                        (quantities[index] ||
                                                          0) >=
                                                        item?.maximum_order_qty
                                                      }
                                                    >
                                                      +
                                                    </button>
                                                  </div>

                                                  {/*  Error Messages */}
                                                  {quantities[index] >=
                                                    item?.maximum_order_qty && (
                                                    <p className="mt-4 text-end text-danger ttt-cen">
                                                      The maximum ticket
                                                      quantity should be{' '}
                                                      {item?.maximum_order_qty}.
                                                    </p>
                                                  )}

                                                  {quantities[index] <
                                                    item?.minimum_order_qty &&
                                                    item?.minimum_order_qty !==
                                                      1 && (
                                                      <p className="mt-4 text-end text-danger ttt-cen">
                                                        The minimum ticket
                                                        quantity should be{' '}
                                                        {
                                                          item?.minimum_order_qty
                                                        }
                                                        .
                                                      </p>
                                                    )}
                                                </div>
                                              )}

                                            {item.purchase_error && (
                                              <p className="mt-4 text-end text-danger ttt-cen">
                                                {item.purchase_error}
                                              </p>
                                            )}
                                            {item?.status !== 'AVAILABLE' && (
                                              <div className="edgtf-el-item-link-holder">
                                                <span className="edgtf-sold-out text-danger">
                                                  {item?.status}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        ))}

                                        <div className="check-now-btn">
                                          <button
                                            itemProp="url"
                                            disabled={isButtonDisabled}
                                            type="button"
                                            onClick={handleSaveToCart}
                                            target="_self"
                                            className={`edgtf-btn edgtf-btn-large edgtf-btn-solid edgtf-btn-orange-white edgtf-el-item-link
                                        ${isButtonDisabled ? ' ' : ''}`}
                                          >
                                            <span className="edgtf-btn-text">
                                              {isCartLoading
                                                ? 'Loading.....'
                                                : 'Save to Cart'}
                                            </span>
                                          </button>
                                          {generalError && (
                                            <div className="error">
                                              {generalError}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* ticket new create */}
                              </div>
                              {eventObject?.organization && (
                                <div className="org-event">
                                  <h5 className="pb-1">Organizer</h5>
                                  <Link
                                    to={`/${encrypt('ORG', eventObject?.organization?.id)}`}
                                    className="add-org-ev"
                                  >
                                    <img
                                      src={
                                        eventObject?.organization?.banner
                                          ?.fullUrl
                                          ? eventObject?.organization?.banner
                                              ?.fullUrl
                                          : '../images/thumbnail.png'
                                      }
                                      alt={eventObject?.organization?.name}
                                    />
                                    <p>{eventObject?.organization?.name}</p>
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="edgtf-content-bottom d-none"
                        style={{ backgroundColor: '#000000' }}
                      >
                        <div className="edgtf-content-bottom-inner edgtf-full-width">
                          <div className="widget edgtf-separator-widget">
                            <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-full-width">
                              <div
                                className="edgtf-separator"
                                style={{
                                  borderStyle: 'solid',
                                  width: '100%',
                                  borderBottomWidth: '1px',
                                  marginTop: '0px',
                                  marginBottom: '130px',
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="widget widget_media_image">
                            <Link to="https://offbeat.qodeinteractive.com/">
                              <img
                                width="424"
                                height="87"
                                src="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png"
                                className="image wp-image-4366  attachment-full size-full"
                                alt="f"
                                style={{ maxWidth: '100%', height: 'auto' }}
                                decoding="async"
                                loading="lazy"
                                srcSet="https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom.png 424w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-150x31.png 150w, https://offbeat.qodeinteractive.com/wp-content/uploads/2018/04/logo-content-bottom-300x62.png 300w"
                                sizes="(max-width: 424px) 100vw, 424px"
                              />
                            </Link>
                          </div>
                          <div className="widget edgtf-separator-widget">
                            <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                              <div
                                className="edgtf-separator"
                                style={{
                                  borderStyle: 'solid',
                                  marginBottom: '0px',
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="widget widget_nav_menu">
                            <div className="menu-footer-menu-container">
                              <ul id="menu-footer-menu" className="menu">
                                <li
                                  id="menu-item-1002"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-1002"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/">
                                    Home
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-3428"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3428"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/reservation-form/">
                                    Reservation Form
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-1005"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1005"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/about-me/">
                                    About
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-1006"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1006"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/menu/">
                                    Menu
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-3430"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3430"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/blog/standard-right-sidebar/">
                                    Blog
                                  </Link>
                                </li>
                                <li
                                  id="menu-item-3429"
                                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3429"
                                >
                                  <Link to="https://offbeat.qodeinteractive.com/contact-us/">
                                    Contact
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="widget edgtf-separator-widget">
                            <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                              <div
                                className="edgtf-separator"
                                style={{
                                  borderStyle: 'solid',
                                  marginBottom: '2px',
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="widget edgtf-social-icons-group-widget text-align-center">
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://www.instagram.com/qodeinteractive/"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_instagram_circle"></span>{' '}
                            </Link>
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://www.facebook.com/QodeInteractive/"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_facebook_circle"></span>{' '}
                            </Link>
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://plus.google.com/"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_googleplus_circle"></span>{' '}
                            </Link>
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://twitter.com/QodeInteractive"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_twitter_circle"></span>{' '}
                            </Link>
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://www.youtube.com/QodeInteractiveVideos"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_youtube_circle"></span>{' '}
                            </Link>
                            <Link
                              className="edgtf-social-icon-widget-holder edgtf-icon-has-hover"
                              data-hover-color="#f45f0b"
                              style={{ color: '#ffffff' }}
                              to="https://vimeo.com/"
                              target="_blank"
                            >
                              <span className="edgtf-social-icon-widget social_vimeo_circle"></span>{' '}
                            </Link>
                          </div>
                          <div className="widget edgtf-separator-widget">
                            <div className="edgtf-separator-holder clearfix  edgtf-separator-center edgtf-separator-normal">
                              <div
                                className="edgtf-separator"
                                style={{
                                  borderStyle: 'solid',
                                  marginBottom: '115px',
                                }}
                              ></div>
                            </div>
                          </div>{' '}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {/* new html start */}
              <Footer />
            </div>
          </div>
        </div>
      )}
      {!eventObject && <NotFound />}
      <div className={type === 'VIEW' ? 'promo-mode' : ' d-none'}>
        <p style={{ color: '#9BF9FF' }}>
          This page is in preview mode and hasn’t been published yet. Please
          don’t share the link
        </p>
      </div>
    </>
  );
};

EventPreview.propTypes = {
  type: PropTypes.string,
  eventId: PropTypes.string,
};

export default EventPreview;
