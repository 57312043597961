/* eslint-disable no-useless-escape */
export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const emailRegExp =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;

/* eslint-enable no-useless-escape */

export const encrypt = (type, id) => {
  const combined = `${type}${id}`;
  // Simple encoding logic (you can replace this with a better algorithm)
  let encoded = btoa(combined);
  // Return the first 6 characters of the encoded string
  return encoded.substring(0, 10);
};

export const decrypt = (encoded) => {
  // Decode the Base64 string
  const decoded = atob(encoded);
  return decoded;
};

export const splitString = (input) => {
  // Use regular expressions to match letters and numbers
  const letters = input.match(/[a-zA-Z]+/g) || []; // Match all letters
  const numbers = input.match(/\d+/g) || []; // Match all numbers

  // Join the arrays back into strings if needed
  const letterString = letters.join('');
  const numberString = numbers.join('');

  return {
    type: letterString,
    key: numberString,
  };
};

// Get payment method mode
export const getModeText = (dataArray) => {
  const foundItem = dataArray?.find((item) => item?.statusText === 'Confirmed');
  return foundItem ? foundItem.modeText : null;
};

export const getPriceFormatByType = (dataArray, type) => {
  const foundItem = dataArray?.find((item) => item.type === type);
  return foundItem ? foundItem.priceFormat : null;
};

export const isDiscountZero = (dataArray) => {
  const discountItem = dataArray?.find((item) => item.type === 'DISCOUNT');
  return discountItem ? discountItem.amount === '0.00' : false;
};

export const getEventSettingData = (dataArray, key) => {
  if (dataArray) {
    const foundItem = dataArray?.find((item) => item.key === key);
    return foundItem ? foundItem.value : null;
  }
};
