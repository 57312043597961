import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Logger from '../../../../Utils/Logger';
import axiosInstance from '../../../../Utils/axiosInstance';
import {
  CHECKOUT_PAYMENT_CONFIRM_ENDPOINT,
  CHECKOUT_PAYMENT_INIT_ENDPOINT,
  PAYMENT_METHOD_ENDPOINT,
} from '../../../../Utils/Endpoints';
import StripeCheckout from './Payment/StripeCheckout';
import { clearItem, getObject } from '../../../../Utils/LocalStorage';
import { getPriceFormatByType } from '../../../../Utils/common';

const CheckOut = () => {
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectedPaymentMethodObject, setSelectedPaymentMethodObject] =
    useState({});
  const [error, setError] = useState(null);
  const orderData = getObject('proceedCheckoutData');
  const navigate = useNavigate();
  useEffect(() => {
    fetchPaymentMethod();
  }, []);

  const fetchPaymentMethod = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(PAYMENT_METHOD_ENDPOINT);
      if (response.data.code === 200) {
        setPaymentMethodList(response.data.data);
        // Check if "STRIPE" exists in the payment method list
        const paymentMethodExists = response.data.data.find(
          (method) => method.identifier === 'STRIPE'
        );
        if (paymentMethodExists) {
          setSelectedPaymentMethodObject(paymentMethodExists);
          setSelectedPaymentMethod(paymentMethodExists?.identifier);
        } else {
          // Handle the case where "STRIPE" is not found, if necessary
          console.warn('STRIPE payment method not found');
        }
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, item) => {
    setSelectedPaymentMethodObject(item);
    setSelectedPaymentMethod(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      let requestObject = {
        payment_method_id: selectedPaymentMethodObject?.id,
        notes: null,
      };

      const response = await axiosInstance.post(
        CHECKOUT_PAYMENT_INIT_ENDPOINT + orderData?.id,
        requestObject
      );

      if (response.data.code === 200) {
        const { id } = response.data.data;

        const responseConfirm = await axiosInstance.post(
          CHECKOUT_PAYMENT_CONFIRM_ENDPOINT + id,
          {}
        );
        if (responseConfirm.data.code === 200) {
          navigate('/thank-you-check-out', {
            state: {
              order: responseConfirm.data.data,
            },
          });
          clearItem('proceedCheckoutData');
        } else {
          setError(responseConfirm.data.message);
        }
      }
    } catch (error) {
      console.error('Catch block error:', error);
      setError(error.response?.data?.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <div className="edgtf-woocommerce-page edgtf-predefined-text-skin edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}
            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-notices-wrapper"></div>
                          <div className="woocommerce-form-login-toggle d-none">
                            <div className="woocommerce-info">
                              Returning customer?
                              <Link to="#" className="showlogin">
                                Click here to login
                              </Link>
                            </div>
                          </div>
                          <form
                            className="woocommerce-form woocommerce-form-login login "
                            method="post"
                            style={{ display: 'none' }}
                          >
                            <p>
                              If you have shopped with us before, please enter
                              your details below. If you are a new customer,
                              please proceed to the Billing section.
                            </p>
                            <p className="form-row form-row-first">
                              <label htmlFor="username">
                                Username or email&nbsp;
                                <span className="required">*</span>
                              </label>
                              <input
                                type="text"
                                className="input-text"
                                name="username"
                                id="username"
                                autoComplete="username"
                              />
                            </p>
                            <p className="form-row form-row-last">
                              <label htmlFor="password">
                                Password&nbsp;
                                <span className="required">*</span>
                              </label>
                              <span className="password-input">
                                <input
                                  className="input-text woocommerce-Input"
                                  type="password"
                                  name="password"
                                  id="password"
                                  autoComplete="current-password"
                                />
                                <span className="show-password-input"></span>
                              </span>
                            </p>
                            <div className="clear"></div>
                            <p className="form-row">
                              <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__rememberme">
                                <input
                                  className="woocommerce-form__input woocommerce-form__input-checkbox"
                                  name="rememberme"
                                  type="checkbox"
                                  id="rememberme"
                                  value="forever"
                                />{' '}
                                <span>Remember me</span>
                              </label>
                              <input
                                type="hidden"
                                id="woocommerce-login-nonce"
                                name="woocommerce-login-nonce"
                                value="90169bc2e7"
                              />
                              <input
                                type="hidden"
                                name="_wp_http_referer"
                                value="/checkout/"
                              />{' '}
                              <input
                                type="hidden"
                                name="redirect"
                                value="https://offbeat.qodeinteractive.com/checkout/"
                              />
                              <button
                                type="submit"
                                className="woocommerce-button button woocommerce-form-login__submit"
                                name="login"
                                value="Login"
                              >
                                Login
                              </button>
                            </p>
                            <p className="lost_password">
                              <Link to="https://offbeat.qodeinteractive.com/my-account/lost-password/">
                                Lost your password?
                              </Link>
                            </p>
                            <div className="clear"></div>
                          </form>
                          <div className="woocommerce-form-coupon-toggle  d-none">
                            <div className="woocommerce-info">
                              Have a coupon?
                              <Link to="#" className="showcoupon">
                                Click here to enter your code
                              </Link>
                            </div>
                          </div>
                          <form
                            className="checkout_coupon woocommerce-form-coupon"
                            method="post"
                            style={{ display: 'none' }}
                          >
                            <p>
                              If you have a coupon code, please apply it below.
                            </p>
                            <p className="form-row form-row-first">
                              <label
                                htmlFor="coupon_code"
                                className="screen-reader-text"
                              >
                                Coupon:
                              </label>
                              <input
                                type="text"
                                name="coupon_code"
                                className="input-text"
                                placeholder="Coupon code"
                                id="coupon_code"
                                value=""
                              />
                            </p>
                            <p className="form-row form-row-last">
                              <button
                                type="submit"
                                className="button"
                                name="apply_coupon"
                                value="Apply coupon"
                              >
                                Apply coupon
                              </button>
                            </p>
                            <div className="clear"></div>
                          </form>
                          <div className="woocommerce-notices-wrapper"></div>
                          <form
                            name="checkout"
                            method="post"
                            className="checkout woocommerce-checkout "
                            action="https://offbeat.qodeinteractive.com/checkout/"
                            encType="multipart/form-data"
                            noValidate="novalidate"
                          >
                            <div
                              className="col2-set d-none"
                              id="customer_details"
                            >
                              <div className="col-1">
                                <wc-order-attribution-inputs>
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_source_type"
                                    value="typein"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_referrer"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_campaign"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_source"
                                    value="(direct)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_medium"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_content"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_id"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_term"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_source_platform"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_creative_format"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_utm_marketing_tactic"
                                    value="(none)"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_session_entry"
                                    value="https://offbeat.qodeinteractive.com/event/greenspan/"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_session_start_time"
                                    value="2024-10-30 05:31:15"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_session_pages"
                                    value="5"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_session_count"
                                    value="2"
                                  />
                                  <input
                                    type="hidden"
                                    name="wc_order_attribution_user_agent"
                                    value="Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36"
                                  />
                                </wc-order-attribution-inputs>
                                <div className="woocommerce-billing-fields">
                                  <h3>Billing details</h3>
                                  <div className="woocommerce-billing-fields__field-wrapper">
                                    <p
                                      className="form-row form-row-first validate-required"
                                      id="billing_first_name_field"
                                      data-priority="10"
                                    >
                                      <label
                                        htmlFor="billing_first_name"
                                        className=""
                                      >
                                        First name&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_first_name"
                                          id="billing_first_name"
                                          placeholder=""
                                          value="ajay"
                                          autoComplete="given-name"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row form-row-last validate-required"
                                      id="billing_last_name_field"
                                      data-priority="20"
                                    >
                                      <label
                                        htmlFor="billing_last_name"
                                        className=""
                                      >
                                        Last name&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_last_name"
                                          id="billing_last_name"
                                          placeholder=""
                                          value="demo"
                                          autoComplete="family-name"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row form-row-wide"
                                      id="billing_company_field"
                                      data-priority="30"
                                    >
                                      <label
                                        htmlFor="billing_company"
                                        className=""
                                      >
                                        Company name&nbsp;
                                        <span className="optional">
                                          (optional)
                                        </span>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_company"
                                          id="billing_company"
                                          placeholder=""
                                          value=""
                                          autoComplete="organization"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row form-row-wide address-field update_totals_on_change validate-required"
                                      id="billing_country_field"
                                      data-priority="40"
                                    >
                                      <label
                                        htmlFor="billing_country"
                                        className=""
                                      >
                                        Country / Region&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select
                                          name="billing_country"
                                          id="billing_country"
                                          className="country_to_state country_select select2-hidden-accessible"
                                          autoComplete="country"
                                          data-placeholder="Select a country / region…"
                                          data-label="Country / Region"
                                          tabIndex="-1"
                                          aria-hidden="true"
                                        >
                                          <option value="">
                                            Select a country / region…
                                          </option>
                                          <option value="AF">
                                            Afghanistan
                                          </option>
                                          <option value="AX">
                                            Åland Islands
                                          </option>
                                          <option value="AL">Albania</option>
                                          <option value="DZ">Algeria</option>
                                          <option value="AS">
                                            American Samoa
                                          </option>
                                          <option value="AD">Andorra</option>
                                          <option value="AO">Angola</option>
                                          <option value="AI">Anguilla</option>
                                          <option value="AQ">Antarctica</option>
                                          <option value="AG">
                                            Antigua and Barbuda
                                          </option>
                                          <option value="AR">Argentina</option>
                                          <option value="AM">Armenia</option>
                                          <option value="AW">Aruba</option>
                                          <option value="AU">Australia</option>
                                          <option value="AT">Austria</option>
                                          <option value="AZ">Azerbaijan</option>
                                          <option value="BS">Bahamas</option>
                                          <option value="BH">Bahrain</option>
                                          <option value="BD">Bangladesh</option>
                                          <option value="BB">Barbados</option>
                                          <option value="BY">Belarus</option>
                                          <option value="PW">Belau</option>
                                          <option value="BE">Belgium</option>
                                          <option value="BZ">Belize</option>
                                          <option value="BJ">Benin</option>
                                          <option value="BM">Bermuda</option>
                                          <option value="BT">Bhutan</option>
                                          <option value="BO">Bolivia</option>
                                          <option value="BQ">
                                            Bonaire, Saint Eustatius and Saba
                                          </option>
                                          <option value="BA">
                                            Bosnia and Herzegovina
                                          </option>
                                          <option value="BW">Botswana</option>
                                          <option value="BV">
                                            Bouvet Island
                                          </option>
                                          <option value="BR">Brazil</option>
                                          <option value="IO">
                                            British Indian Ocean Territory
                                          </option>
                                          <option value="BN">Brunei</option>
                                          <option value="BG">Bulgaria</option>
                                          <option value="BF">
                                            Burkina Faso
                                          </option>
                                          <option value="BI">Burundi</option>
                                          <option value="KH">Cambodia</option>
                                          <option value="CM">Cameroon</option>
                                          <option value="CA">Canada</option>
                                          <option value="CV">Cape Verde</option>
                                          <option value="KY">
                                            Cayman Islands
                                          </option>
                                          <option value="CF">
                                            Central African Republic
                                          </option>
                                          <option value="TD">Chad</option>
                                          <option value="CL">Chile</option>
                                          <option value="CN">China</option>
                                          <option value="CX">
                                            Christmas Island
                                          </option>
                                          <option value="CC">
                                            Cocos (Keeling) Islands
                                          </option>
                                          <option value="CO">Colombia</option>
                                          <option value="KM">Comoros</option>
                                          <option value="CG">
                                            Congo (Brazzaville)
                                          </option>
                                          <option value="CD">
                                            Congo (Kinshasa)
                                          </option>
                                          <option value="CK">
                                            Cook Islands
                                          </option>
                                          <option value="CR">Costa Rica</option>
                                          <option value="HR">Croatia</option>
                                          <option value="CU">Cuba</option>
                                          <option value="CW">Curaçao</option>
                                          <option value="CY">Cyprus</option>
                                          <option value="CZ">
                                            Czech Republic
                                          </option>
                                          <option value="DK">Denmark</option>
                                          <option value="DJ">Djibouti</option>
                                          <option value="DM">Dominica</option>
                                          <option value="DO">
                                            Dominican Republic
                                          </option>
                                          <option value="EC">Ecuador</option>
                                          <option value="EG">Egypt</option>
                                          <option value="SV">
                                            El Salvador
                                          </option>
                                          <option value="GQ">
                                            Equatorial Guinea
                                          </option>
                                          <option value="ER">Eritrea</option>
                                          <option value="EE">Estonia</option>
                                          <option value="SZ">Eswatini</option>
                                          <option value="ET">Ethiopia</option>
                                          <option value="FK">
                                            Falkland Islands
                                          </option>
                                          <option value="FO">
                                            Faroe Islands
                                          </option>
                                          <option value="FJ">Fiji</option>
                                          <option value="FI">Finland</option>
                                          <option value="FR">France</option>
                                          <option value="GF">
                                            French Guiana
                                          </option>
                                          <option value="PF">
                                            French Polynesia
                                          </option>
                                          <option value="TF">
                                            French Southern Territories
                                          </option>
                                          <option value="GA">Gabon</option>
                                          <option value="GM">Gambia</option>
                                          <option value="GE">Georgia</option>
                                          <option value="DE">Germany</option>
                                          <option value="GH">Ghana</option>
                                          <option value="GI">Gibraltar</option>
                                          <option value="GR">Greece</option>
                                          <option value="GL">Greenland</option>
                                          <option value="GD">Grenada</option>
                                          <option value="GP">Guadeloupe</option>
                                          <option value="GU">Guam</option>
                                          <option value="GT">Guatemala</option>
                                          <option value="GG">Guernsey</option>
                                          <option value="GN">Guinea</option>
                                          <option value="GW">
                                            Guinea-Bissau
                                          </option>
                                          <option value="GY">Guyana</option>
                                          <option value="HT">Haiti</option>
                                          <option value="HM">
                                            Heard Island and McDonald Islands
                                          </option>
                                          <option value="HN">Honduras</option>
                                          <option value="HK">Hong Kong</option>
                                          <option value="HU">Hungary</option>
                                          <option value="IS">Iceland</option>
                                          <option value="IN">India</option>
                                          <option value="ID">Indonesia</option>
                                          <option value="IR">Iran</option>
                                          <option value="IQ">Iraq</option>
                                          <option value="IE">Ireland</option>
                                          <option value="IM">
                                            Isle of Man
                                          </option>
                                          <option value="IL">Israel</option>
                                          <option value="IT">Italy</option>
                                          <option value="CI">
                                            Ivory Coast
                                          </option>
                                          <option value="JM">Jamaica</option>
                                          <option value="JP">Japan</option>
                                          <option value="JE">Jersey</option>
                                          <option value="JO">Jordan</option>
                                          <option value="KZ">Kazakhstan</option>
                                          <option value="KE">Kenya</option>
                                          <option value="KI">Kiribati</option>
                                          <option value="KW">Kuwait</option>
                                          <option value="KG">Kyrgyzstan</option>
                                          <option value="LA">Laos</option>
                                          <option value="LV">Latvia</option>
                                          <option value="LB">Lebanon</option>
                                          <option value="LS">Lesotho</option>
                                          <option value="LR">Liberia</option>
                                          <option value="LY">Libya</option>
                                          <option value="LI">
                                            Liechtenstein
                                          </option>
                                          <option value="LT">Lithuania</option>
                                          <option value="LU">Luxembourg</option>
                                          <option value="MO">Macao</option>
                                          <option value="MG">Madagascar</option>
                                          <option value="MW">Malawi</option>
                                          <option value="MY">Malaysia</option>
                                          <option value="MV">Maldives</option>
                                          <option value="ML">Mali</option>
                                          <option value="MT">Malta</option>
                                          <option value="MH">
                                            Marshall Islands
                                          </option>
                                          <option value="MQ">Martinique</option>
                                          <option value="MR">Mauritania</option>
                                          <option value="MU">Mauritius</option>
                                          <option value="YT">Mayotte</option>
                                          <option value="MX">Mexico</option>
                                          <option value="FM">Micronesia</option>
                                          <option value="MD">Moldova</option>
                                          <option value="MC">Monaco</option>
                                          <option value="MN">Mongolia</option>
                                          <option value="ME">Montenegro</option>
                                          <option value="MS">Montserrat</option>
                                          <option value="MA">Morocco</option>
                                          <option value="MZ">Mozambique</option>
                                          <option value="MM">Myanmar</option>
                                          <option value="NA">Namibia</option>
                                          <option value="NR">Nauru</option>
                                          <option value="NP">Nepal</option>
                                          <option value="NL">
                                            Netherlands
                                          </option>
                                          <option value="NC">
                                            New Caledonia
                                          </option>
                                          <option value="NZ">
                                            New Zealand
                                          </option>
                                          <option value="NI">Nicaragua</option>
                                          <option value="NE">Niger</option>
                                          <option value="NG">Nigeria</option>
                                          <option value="NU">Niue</option>
                                          <option value="NF">
                                            Norfolk Island
                                          </option>
                                          <option value="KP">
                                            North Korea
                                          </option>
                                          <option value="MK">
                                            North Macedonia
                                          </option>
                                          <option value="MP">
                                            Northern Mariana Islands
                                          </option>
                                          <option value="NO">Norway</option>
                                          <option value="OM">Oman</option>
                                          <option value="PK">Pakistan</option>
                                          <option value="PS">
                                            Palestinian Territory
                                          </option>
                                          <option value="PA">Panama</option>
                                          <option value="PG">
                                            Papua New Guinea
                                          </option>
                                          <option value="PY">Paraguay</option>
                                          <option value="PE">Peru</option>
                                          <option value="PH">
                                            Philippines
                                          </option>
                                          <option value="PN">Pitcairn</option>
                                          <option value="PL">Poland</option>
                                          <option value="PT">Portugal</option>
                                          <option value="PR">
                                            Puerto Rico
                                          </option>
                                          <option value="QA">Qatar</option>
                                          <option value="RE">Reunion</option>
                                          <option value="RO">Romania</option>
                                          <option value="RU">Russia</option>
                                          <option value="RW">Rwanda</option>
                                          <option value="ST">
                                            São Tomé and Príncipe
                                          </option>
                                          <option value="BL">
                                            Saint Barthélemy
                                          </option>
                                          <option value="SH">
                                            Saint Helena
                                          </option>
                                          <option value="KN">
                                            Saint Kitts and Nevis
                                          </option>
                                          <option value="LC">
                                            Saint Lucia
                                          </option>
                                          <option value="SX">
                                            Saint Martin (Dutch part)
                                          </option>
                                          <option value="MF">
                                            Saint Martin (French part)
                                          </option>
                                          <option value="PM">
                                            Saint Pierre and Miquelon
                                          </option>
                                          <option value="VC">
                                            Saint Vincent and the Grenadines
                                          </option>
                                          <option value="WS">Samoa</option>
                                          <option value="SM">San Marino</option>
                                          <option value="SA">
                                            Saudi Arabia
                                          </option>
                                          <option value="SN">Senegal</option>
                                          <option value="RS">Serbia</option>
                                          <option value="SC">Seychelles</option>
                                          <option value="SL">
                                            Sierra Leone
                                          </option>
                                          <option value="SG">Singapore</option>
                                          <option value="SK">Slovakia</option>
                                          <option value="SI">Slovenia</option>
                                          <option value="SB">
                                            Solomon Islands
                                          </option>
                                          <option value="SO">Somalia</option>
                                          <option value="ZA">
                                            South Africa
                                          </option>
                                          <option value="GS">
                                            South Georgia/Sandwich Islands
                                          </option>
                                          <option value="KR">
                                            South Korea
                                          </option>
                                          <option value="SS">
                                            South Sudan
                                          </option>
                                          <option value="ES">Spain</option>
                                          <option value="LK">Sri Lanka</option>
                                          <option value="SD">Sudan</option>
                                          <option value="SR">Suriname</option>
                                          <option value="SJ">
                                            Svalbard and Jan Mayen
                                          </option>
                                          <option value="SE">Sweden</option>
                                          <option value="CH">
                                            Switzerland
                                          </option>
                                          <option value="SY">Syria</option>
                                          <option value="TW">Taiwan</option>
                                          <option value="TJ">Tajikistan</option>
                                          <option value="TZ">Tanzania</option>
                                          <option value="TH">Thailand</option>
                                          <option value="TL">
                                            Timor-Leste
                                          </option>
                                          <option value="TG">Togo</option>
                                          <option value="TK">Tokelau</option>
                                          <option value="TO">Tonga</option>
                                          <option value="TT">
                                            Trinidad and Tobago
                                          </option>
                                          <option value="TN">Tunisia</option>
                                          <option value="TR">Turkey</option>
                                          <option value="TM">
                                            Turkmenistan
                                          </option>
                                          <option value="TC">
                                            Turks and Caicos Islands
                                          </option>
                                          <option value="TV">Tuvalu</option>
                                          <option value="UG">Uganda</option>
                                          <option value="UA">Ukraine</option>
                                          <option value="AE">
                                            United Arab Emirates
                                          </option>
                                          <option value="GB">
                                            United Kingdom (UK)
                                          </option>
                                          <option
                                            value="US"
                                            selected="selected"
                                          >
                                            United States (US)
                                          </option>
                                          <option value="UM">
                                            United States (US) Minor Outlying
                                            Islands
                                          </option>
                                          <option value="UY">Uruguay</option>
                                          <option value="UZ">Uzbekistan</option>
                                          <option value="VU">Vanuatu</option>
                                          <option value="VA">Vatican</option>
                                          <option value="VE">Venezuela</option>
                                          <option value="VN">Vietnam</option>
                                          <option value="VG">
                                            Virgin Islands (British)
                                          </option>
                                          <option value="VI">
                                            Virgin Islands (US)
                                          </option>
                                          <option value="WF">
                                            Wallis and Futuna
                                          </option>
                                          <option value="EH">
                                            Western Sahara
                                          </option>
                                          <option value="YE">Yemen</option>
                                          <option value="ZM">Zambia</option>
                                          <option value="ZW">Zimbabwe</option>
                                        </select>
                                        <span
                                          className="select2 select2-container select2-container--default"
                                          dir="ltr"
                                          style={{ width: '100%' }}
                                        >
                                          <span className="selection">
                                            <span
                                              className="select2-selection select2-selection--single"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              tabIndex="0"
                                              aria-label="Country / Region"
                                              role="combobox"
                                            >
                                              <span
                                                className="select2-selection__rendered"
                                                id="select2-billing_country-container"
                                                role="textbox"
                                                aria-readonly="true"
                                                title="United States (US)"
                                              >
                                                United States (US)
                                              </span>
                                              <span
                                                className="select2-selection__arrow"
                                                role="presentation"
                                              >
                                                <b role="presentation"></b>
                                              </span>
                                            </span>
                                          </span>
                                          <span
                                            className="dropdown-wrapper"
                                            aria-hidden="true"
                                          ></span>
                                        </span>
                                        <noscript>
                                          <button
                                            type="submit"
                                            name="woocommerce_checkout_update_totals"
                                            value="Update country / region"
                                          >
                                            Update country / region
                                          </button>
                                        </noscript>
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required form-row-wide"
                                      id="billing_address_1_field"
                                      data-priority="50"
                                    >
                                      <label
                                        htmlFor="billing_address_1"
                                        className=""
                                      >
                                        Street address&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_address_1"
                                          id="billing_address_1"
                                          placeholder="House number and street name"
                                          value="Gatlinburg, TN 37738, Gatlinburg, TN 37738"
                                          autoComplete="address-line1"
                                          data-placeholder="House number and street name"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field form-row-wide"
                                      id="billing_address_2_field"
                                      data-priority="60"
                                    >
                                      <label
                                        htmlFor="billing_address_2"
                                        className="screen-reader-text"
                                      >
                                        Apartment, suite, unit, etc.&nbsp;
                                        <span className="optional">
                                          (optional)
                                        </span>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_address_2"
                                          id="billing_address_2"
                                          placeholder="Apartment, suite, unit, etc. (optional)"
                                          value=""
                                          autoComplete="address-line2"
                                          data-placeholder="Apartment, suite, unit, etc. (optional)"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required form-row-wide"
                                      id="billing_city_field"
                                      data-priority="70"
                                      data-o_className="form-row form-row-wide address-field validate-required"
                                    >
                                      <label
                                        htmlFor="billing_city"
                                        className=""
                                      >
                                        Town / City&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_city"
                                          id="billing_city"
                                          placeholder=""
                                          value="Gatlinburg"
                                          autoComplete="address-level2"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required validate-state form-row-wide"
                                      id="billing_state_field"
                                      data-priority="80"
                                      data-o_className="form-row form-row-wide address-field validate-required validate-state"
                                    >
                                      <label
                                        htmlFor="billing_state"
                                        className=""
                                      >
                                        State&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select
                                          name="billing_state"
                                          id="billing_state"
                                          className="state_select select2-hidden-accessible"
                                          autoComplete="address-level1"
                                          data-placeholder="Select an option…"
                                          data-input-classes=""
                                          data-label="State"
                                          tabIndex="-1"
                                          aria-hidden="true"
                                        >
                                          <option value="">
                                            Select an option…
                                          </option>
                                          <option value="AL">Alabama</option>
                                          <option value="AK">Alaska</option>
                                          <option value="AZ">Arizona</option>
                                          <option value="AR">Arkansas</option>
                                          <option value="CA">California</option>
                                          <option value="CO">Colorado</option>
                                          <option value="CT">
                                            Connecticut
                                          </option>
                                          <option value="DE">Delaware</option>
                                          <option value="DC">
                                            District Of Columbia
                                          </option>
                                          <option value="FL">Florida</option>
                                          <option value="GA">Georgia</option>
                                          <option value="HI">Hawaii</option>
                                          <option value="ID">Idaho</option>
                                          <option value="IL">Illinois</option>
                                          <option value="IN">Indiana</option>
                                          <option value="IA">Iowa</option>
                                          <option value="KS">Kansas</option>
                                          <option value="KY">Kentucky</option>
                                          <option value="LA">Louisiana</option>
                                          <option value="ME">Maine</option>
                                          <option value="MD">Maryland</option>
                                          <option value="MA">
                                            Massachusetts
                                          </option>
                                          <option value="MI">Michigan</option>
                                          <option value="MN">Minnesota</option>
                                          <option value="MS">
                                            Mississippi
                                          </option>
                                          <option value="MO">Missouri</option>
                                          <option value="MT">Montana</option>
                                          <option value="NE">Nebraska</option>
                                          <option value="NV">Nevada</option>
                                          <option value="NH">
                                            New Hampshire
                                          </option>
                                          <option value="NJ">New Jersey</option>
                                          <option value="NM">New Mexico</option>
                                          <option value="NY">New York</option>
                                          <option value="NC">
                                            North Carolina
                                          </option>
                                          <option value="ND">
                                            North Dakota
                                          </option>
                                          <option value="OH">Ohio</option>
                                          <option value="OK">Oklahoma</option>
                                          <option value="OR">Oregon</option>
                                          <option value="PA">
                                            Pennsylvania
                                          </option>
                                          <option value="RI">
                                            Rhode Island
                                          </option>
                                          <option value="SC">
                                            South Carolina
                                          </option>
                                          <option value="SD">
                                            South Dakota
                                          </option>
                                          <option value="TN">Tennessee</option>
                                          <option value="TX">Texas</option>
                                          <option value="UT">Utah</option>
                                          <option value="VT">Vermont</option>
                                          <option value="VA">Virginia</option>
                                          <option value="WA">Washington</option>
                                          <option value="WV">
                                            West Virginia
                                          </option>
                                          <option value="WI">Wisconsin</option>
                                          <option value="WY">Wyoming</option>
                                          <option value="AA">
                                            Armed Forces (AA)
                                          </option>
                                          <option value="AE">
                                            Armed Forces (AE)
                                          </option>
                                          <option value="AP">
                                            Armed Forces (AP)
                                          </option>
                                        </select>
                                        <span
                                          className="select2 select2-container select2-container--default"
                                          dir="ltr"
                                          style={{ width: '100%' }}
                                        >
                                          <span className="selection">
                                            <span
                                              className="select2-selection select2-selection--single"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                              tabIndex="0"
                                              aria-label="State"
                                              role="combobox"
                                            >
                                              <span
                                                className="select2-selection__rendered"
                                                id="select2-billing_state-container"
                                                role="textbox"
                                                aria-readonly="true"
                                                title="Tennessee"
                                              >
                                                Tennessee
                                              </span>
                                              <span
                                                className="select2-selection__arrow"
                                                role="presentation"
                                              >
                                                <b role="presentation"></b>
                                              </span>
                                            </span>
                                          </span>
                                          <span
                                            className="dropdown-wrapper"
                                            aria-hidden="true"
                                          ></span>
                                        </span>
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required validate-postcode form-row-wide"
                                      id="billing_postcode_field"
                                      data-priority="90"
                                      data-o_className="form-row form-row-wide address-field validate-required validate-postcode"
                                    >
                                      <label
                                        htmlFor="billing_postcode"
                                        className=""
                                      >
                                        ZIP Code&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="billing_postcode"
                                          id="billing_postcode"
                                          placeholder=""
                                          value="37738"
                                          autoComplete="postal-code"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row form-row-wide validate-required validate-phone"
                                      id="billing_phone_field"
                                      data-priority="100"
                                    >
                                      <label
                                        htmlFor="billing_phone"
                                        className=""
                                      >
                                        Phone&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="tel"
                                          className="input-text "
                                          name="billing_phone"
                                          id="billing_phone"
                                          placeholder=""
                                          value="+919877781112"
                                          autoComplete="tel"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row form-row-wide validate-required validate-email"
                                      id="billing_email_field"
                                      data-priority="110"
                                    >
                                      <label
                                        htmlFor="billing_email"
                                        className=""
                                      >
                                        Email address&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="email"
                                          className="input-text "
                                          name="billing_email"
                                          id="billing_email"
                                          placeholder=""
                                          value="ajay.chayal@yatilabs.com"
                                          autoComplete="email username"
                                        />
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="woocommerce-account-fields">
                                  <p className="form-row form-row-wide create-account woocommerce-validated">
                                    <label className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                                      <input
                                        className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                        id="createaccount"
                                        type="checkbox"
                                        name="createaccount"
                                        value="1"
                                      />{' '}
                                      <span>Create an account?</span>
                                    </label>
                                  </p>
                                  <div
                                    className="create-account"
                                    style={{ display: 'none' }}
                                  >
                                    <p
                                      className="form-row validate-required"
                                      id="account_password_field"
                                      data-priority=""
                                    >
                                      <label
                                        htmlFor="account_password"
                                        className=""
                                      >
                                        Create account password&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper password-input">
                                        <input
                                          type="password"
                                          className="input-text "
                                          name="account_password"
                                          id="account_password"
                                          placeholder="Password"
                                          value=""
                                          autoComplete="new-password"
                                        />
                                        <span className="show-password-input"></span>
                                      </span>
                                    </p>
                                    <div className="clear"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="woocommerce-shipping-fields">
                                  <h3 id="ship-to-different-address">
                                    <label className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                                      <input
                                        id="ship-to-different-address-checkbox"
                                        className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                        type="checkbox"
                                        name="ship_to_different_address"
                                        value="1"
                                      />{' '}
                                      <span>Ship to a different address?</span>
                                    </label>
                                  </h3>
                                  <div
                                    className="shipping_address"
                                    style={{ display: 'none' }}
                                  >
                                    <div className="woocommerce-shipping-fields__field-wrapper">
                                      <p
                                        className="form-row form-row-first validate-required"
                                        id="shipping_first_name_field"
                                        data-priority="10"
                                      >
                                        <label
                                          htmlFor="shipping_first_name"
                                          className=""
                                        >
                                          First name&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_first_name"
                                            id="shipping_first_name"
                                            placeholder=""
                                            value="ajay"
                                            autoComplete="given-name"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row form-row-last validate-required"
                                        id="shipping_last_name_field"
                                        data-priority="20"
                                      >
                                        <label
                                          htmlFor="shipping_last_name"
                                          className=""
                                        >
                                          Last name&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_last_name"
                                            id="shipping_last_name"
                                            placeholder=""
                                            value="demo"
                                            autoComplete="family-name"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row form-row-wide"
                                        id="shipping_company_field"
                                        data-priority="30"
                                      >
                                        <label
                                          htmlFor="shipping_company"
                                          className=""
                                        >
                                          Company name&nbsp;
                                          <span className="optional">
                                            (optional)
                                          </span>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_company"
                                            id="shipping_company"
                                            placeholder=""
                                            value=""
                                            autoComplete="organization"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row form-row-wide address-field update_totals_on_change validate-required"
                                        id="shipping_country_field"
                                        data-priority="40"
                                      >
                                        <label
                                          htmlFor="shipping_country"
                                          className=""
                                        >
                                          Country / Region&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <select
                                            name="shipping_country"
                                            id="shipping_country"
                                            className="country_to_state country_select select2-hidden-accessible"
                                            autoComplete="country"
                                            data-placeholder="Select a country / region…"
                                            data-label="Country / Region"
                                            tabIndex="-1"
                                            aria-hidden="true"
                                          >
                                            <option value="">
                                              Select a country / region…
                                            </option>
                                            <option value="AF">
                                              Afghanistan
                                            </option>
                                            <option value="AX">
                                              Åland Islands
                                            </option>
                                            <option value="AL">Albania</option>
                                            <option value="DZ">Algeria</option>
                                            <option value="AS">
                                              American Samoa
                                            </option>
                                            <option value="AD">Andorra</option>
                                            <option value="AO">Angola</option>
                                            <option value="AI">Anguilla</option>
                                            <option value="AQ">
                                              Antarctica
                                            </option>
                                            <option value="AG">
                                              Antigua and Barbuda
                                            </option>
                                            <option value="AR">
                                              Argentina
                                            </option>
                                            <option value="AM">Armenia</option>
                                            <option value="AW">Aruba</option>
                                            <option value="AU">
                                              Australia
                                            </option>
                                            <option value="AT">Austria</option>
                                            <option value="AZ">
                                              Azerbaijan
                                            </option>
                                            <option value="BS">Bahamas</option>
                                            <option value="BH">Bahrain</option>
                                            <option value="BD">
                                              Bangladesh
                                            </option>
                                            <option value="BB">Barbados</option>
                                            <option value="BY">Belarus</option>
                                            <option value="PW">Belau</option>
                                            <option value="BE">Belgium</option>
                                            <option value="BZ">Belize</option>
                                            <option value="BJ">Benin</option>
                                            <option value="BM">Bermuda</option>
                                            <option value="BT">Bhutan</option>
                                            <option value="BO">Bolivia</option>
                                            <option value="BQ">
                                              Bonaire, Saint Eustatius and Saba
                                            </option>
                                            <option value="BA">
                                              Bosnia and Herzegovina
                                            </option>
                                            <option value="BW">Botswana</option>
                                            <option value="BV">
                                              Bouvet Island
                                            </option>
                                            <option value="BR">Brazil</option>
                                            <option value="IO">
                                              British Indian Ocean Territory
                                            </option>
                                            <option value="BN">Brunei</option>
                                            <option value="BG">Bulgaria</option>
                                            <option value="BF">
                                              Burkina Faso
                                            </option>
                                            <option value="BI">Burundi</option>
                                            <option value="KH">Cambodia</option>
                                            <option value="CM">Cameroon</option>
                                            <option value="CA">Canada</option>
                                            <option value="CV">
                                              Cape Verde
                                            </option>
                                            <option value="KY">
                                              Cayman Islands
                                            </option>
                                            <option value="CF">
                                              Central African Republic
                                            </option>
                                            <option value="TD">Chad</option>
                                            <option value="CL">Chile</option>
                                            <option value="CN">China</option>
                                            <option value="CX">
                                              Christmas Island
                                            </option>
                                            <option value="CC">
                                              Cocos (Keeling) Islands
                                            </option>
                                            <option value="CO">Colombia</option>
                                            <option value="KM">Comoros</option>
                                            <option value="CG">
                                              Congo (Brazzaville)
                                            </option>
                                            <option value="CD">
                                              Congo (Kinshasa)
                                            </option>
                                            <option value="CK">
                                              Cook Islands
                                            </option>
                                            <option value="CR">
                                              Costa Rica
                                            </option>
                                            <option value="HR">Croatia</option>
                                            <option value="CU">Cuba</option>
                                            <option value="CW">Curaçao</option>
                                            <option value="CY">Cyprus</option>
                                            <option value="CZ">
                                              Czech Republic
                                            </option>
                                            <option value="DK">Denmark</option>
                                            <option value="DJ">Djibouti</option>
                                            <option value="DM">Dominica</option>
                                            <option value="DO">
                                              Dominican Republic
                                            </option>
                                            <option value="EC">Ecuador</option>
                                            <option value="EG">Egypt</option>
                                            <option value="SV">
                                              El Salvador
                                            </option>
                                            <option value="GQ">
                                              Equatorial Guinea
                                            </option>
                                            <option value="ER">Eritrea</option>
                                            <option value="EE">Estonia</option>
                                            <option value="SZ">Eswatini</option>
                                            <option value="ET">Ethiopia</option>
                                            <option value="FK">
                                              Falkland Islands
                                            </option>
                                            <option value="FO">
                                              Faroe Islands
                                            </option>
                                            <option value="FJ">Fiji</option>
                                            <option value="FI">Finland</option>
                                            <option value="FR">France</option>
                                            <option value="GF">
                                              French Guiana
                                            </option>
                                            <option value="PF">
                                              French Polynesia
                                            </option>
                                            <option value="TF">
                                              French Southern Territories
                                            </option>
                                            <option value="GA">Gabon</option>
                                            <option value="GM">Gambia</option>
                                            <option value="GE">Georgia</option>
                                            <option value="DE">Germany</option>
                                            <option value="GH">Ghana</option>
                                            <option value="GI">
                                              Gibraltar
                                            </option>
                                            <option value="GR">Greece</option>
                                            <option value="GL">
                                              Greenland
                                            </option>
                                            <option value="GD">Grenada</option>
                                            <option value="GP">
                                              Guadeloupe
                                            </option>
                                            <option value="GU">Guam</option>
                                            <option value="GT">
                                              Guatemala
                                            </option>
                                            <option value="GG">Guernsey</option>
                                            <option value="GN">Guinea</option>
                                            <option value="GW">
                                              Guinea-Bissau
                                            </option>
                                            <option value="GY">Guyana</option>
                                            <option value="HT">Haiti</option>
                                            <option value="HM">
                                              Heard Island and McDonald Islands
                                            </option>
                                            <option value="HN">Honduras</option>
                                            <option value="HK">
                                              Hong Kong
                                            </option>
                                            <option value="HU">Hungary</option>
                                            <option value="IS">Iceland</option>
                                            <option value="IN">India</option>
                                            <option value="ID">
                                              Indonesia
                                            </option>
                                            <option value="IR">Iran</option>
                                            <option value="IQ">Iraq</option>
                                            <option value="IE">Ireland</option>
                                            <option value="IM">
                                              Isle of Man
                                            </option>
                                            <option value="IL">Israel</option>
                                            <option value="IT">Italy</option>
                                            <option value="CI">
                                              Ivory Coast
                                            </option>
                                            <option value="JM">Jamaica</option>
                                            <option value="JP">Japan</option>
                                            <option value="JE">Jersey</option>
                                            <option value="JO">Jordan</option>
                                            <option value="KZ">
                                              Kazakhstan
                                            </option>
                                            <option value="KE">Kenya</option>
                                            <option value="KI">Kiribati</option>
                                            <option value="KW">Kuwait</option>
                                            <option value="KG">
                                              Kyrgyzstan
                                            </option>
                                            <option value="LA">Laos</option>
                                            <option value="LV">Latvia</option>
                                            <option value="LB">Lebanon</option>
                                            <option value="LS">Lesotho</option>
                                            <option value="LR">Liberia</option>
                                            <option value="LY">Libya</option>
                                            <option value="LI">
                                              Liechtenstein
                                            </option>
                                            <option value="LT">
                                              Lithuania
                                            </option>
                                            <option value="LU">
                                              Luxembourg
                                            </option>
                                            <option value="MO">Macao</option>
                                            <option value="MG">
                                              Madagascar
                                            </option>
                                            <option value="MW">Malawi</option>
                                            <option value="MY">Malaysia</option>
                                            <option value="MV">Maldives</option>
                                            <option value="ML">Mali</option>
                                            <option value="MT">Malta</option>
                                            <option value="MH">
                                              Marshall Islands
                                            </option>
                                            <option value="MQ">
                                              Martinique
                                            </option>
                                            <option value="MR">
                                              Mauritania
                                            </option>
                                            <option value="MU">
                                              Mauritius
                                            </option>
                                            <option value="YT">Mayotte</option>
                                            <option value="MX">Mexico</option>
                                            <option value="FM">
                                              Micronesia
                                            </option>
                                            <option value="MD">Moldova</option>
                                            <option value="MC">Monaco</option>
                                            <option value="MN">Mongolia</option>
                                            <option value="ME">
                                              Montenegro
                                            </option>
                                            <option value="MS">
                                              Montserrat
                                            </option>
                                            <option value="MA">Morocco</option>
                                            <option value="MZ">
                                              Mozambique
                                            </option>
                                            <option value="MM">Myanmar</option>
                                            <option value="NA">Namibia</option>
                                            <option value="NR">Nauru</option>
                                            <option value="NP">Nepal</option>
                                            <option value="NL">
                                              Netherlands
                                            </option>
                                            <option value="NC">
                                              New Caledonia
                                            </option>
                                            <option value="NZ">
                                              New Zealand
                                            </option>
                                            <option value="NI">
                                              Nicaragua
                                            </option>
                                            <option value="NE">Niger</option>
                                            <option value="NG">Nigeria</option>
                                            <option value="NU">Niue</option>
                                            <option value="NF">
                                              Norfolk Island
                                            </option>
                                            <option value="KP">
                                              North Korea
                                            </option>
                                            <option value="MK">
                                              North Macedonia
                                            </option>
                                            <option value="MP">
                                              Northern Mariana Islands
                                            </option>
                                            <option value="NO">Norway</option>
                                            <option value="OM">Oman</option>
                                            <option value="PK">Pakistan</option>
                                            <option value="PS">
                                              Palestinian Territory
                                            </option>
                                            <option value="PA">Panama</option>
                                            <option value="PG">
                                              Papua New Guinea
                                            </option>
                                            <option value="PY">Paraguay</option>
                                            <option value="PE">Peru</option>
                                            <option value="PH">
                                              Philippines
                                            </option>
                                            <option value="PN">Pitcairn</option>
                                            <option value="PL">Poland</option>
                                            <option value="PT">Portugal</option>
                                            <option value="PR">
                                              Puerto Rico
                                            </option>
                                            <option value="QA">Qatar</option>
                                            <option value="RE">Reunion</option>
                                            <option value="RO">Romania</option>
                                            <option value="RU">Russia</option>
                                            <option value="RW">Rwanda</option>
                                            <option value="ST">
                                              São Tomé and Príncipe
                                            </option>
                                            <option value="BL">
                                              Saint Barthélemy
                                            </option>
                                            <option value="SH">
                                              Saint Helena
                                            </option>
                                            <option value="KN">
                                              Saint Kitts and Nevis
                                            </option>
                                            <option value="LC">
                                              Saint Lucia
                                            </option>
                                            <option value="SX">
                                              Saint Martin (Dutch part)
                                            </option>
                                            <option value="MF">
                                              Saint Martin (French part)
                                            </option>
                                            <option value="PM">
                                              Saint Pierre and Miquelon
                                            </option>
                                            <option value="VC">
                                              Saint Vincent and the Grenadines
                                            </option>
                                            <option value="WS">Samoa</option>
                                            <option value="SM">
                                              San Marino
                                            </option>
                                            <option value="SA">
                                              Saudi Arabia
                                            </option>
                                            <option value="SN">Senegal</option>
                                            <option value="RS">Serbia</option>
                                            <option value="SC">
                                              Seychelles
                                            </option>
                                            <option value="SL">
                                              Sierra Leone
                                            </option>
                                            <option value="SG">
                                              Singapore
                                            </option>
                                            <option value="SK">Slovakia</option>
                                            <option value="SI">Slovenia</option>
                                            <option value="SB">
                                              Solomon Islands
                                            </option>
                                            <option value="SO">Somalia</option>
                                            <option value="ZA">
                                              South Africa
                                            </option>
                                            <option value="GS">
                                              South Georgia/Sandwich Islands
                                            </option>
                                            <option value="KR">
                                              South Korea
                                            </option>
                                            <option value="SS">
                                              South Sudan
                                            </option>
                                            <option value="ES">Spain</option>
                                            <option value="LK">
                                              Sri Lanka
                                            </option>
                                            <option value="SD">Sudan</option>
                                            <option value="SR">Suriname</option>
                                            <option value="SJ">
                                              Svalbard and Jan Mayen
                                            </option>
                                            <option value="SE">Sweden</option>
                                            <option value="CH">
                                              Switzerland
                                            </option>
                                            <option value="SY">Syria</option>
                                            <option value="TW">Taiwan</option>
                                            <option value="TJ">
                                              Tajikistan
                                            </option>
                                            <option value="TZ">Tanzania</option>
                                            <option value="TH">Thailand</option>
                                            <option value="TL">
                                              Timor-Leste
                                            </option>
                                            <option value="TG">Togo</option>
                                            <option value="TK">Tokelau</option>
                                            <option value="TO">Tonga</option>
                                            <option value="TT">
                                              Trinidad and Tobago
                                            </option>
                                            <option value="TN">Tunisia</option>
                                            <option value="TR">Turkey</option>
                                            <option value="TM">
                                              Turkmenistan
                                            </option>
                                            <option value="TC">
                                              Turks and Caicos Islands
                                            </option>
                                            <option value="TV">Tuvalu</option>
                                            <option value="UG">Uganda</option>
                                            <option value="UA">Ukraine</option>
                                            <option value="AE">
                                              United Arab Emirates
                                            </option>
                                            <option value="GB">
                                              United Kingdom (UK)
                                            </option>
                                            <option
                                              value="US"
                                              selected="selected"
                                            >
                                              United States (US)
                                            </option>
                                            <option value="UM">
                                              United States (US) Minor Outlying
                                              Islands
                                            </option>
                                            <option value="UY">Uruguay</option>
                                            <option value="UZ">
                                              Uzbekistan
                                            </option>
                                            <option value="VU">Vanuatu</option>
                                            <option value="VA">Vatican</option>
                                            <option value="VE">
                                              Venezuela
                                            </option>
                                            <option value="VN">Vietnam</option>
                                            <option value="VG">
                                              Virgin Islands (British)
                                            </option>
                                            <option value="VI">
                                              Virgin Islands (US)
                                            </option>
                                            <option value="WF">
                                              Wallis and Futuna
                                            </option>
                                            <option value="EH">
                                              Western Sahara
                                            </option>
                                            <option value="YE">Yemen</option>
                                            <option value="ZM">Zambia</option>
                                            <option value="ZW">Zimbabwe</option>
                                          </select>
                                          <span
                                            className="select2 select2-container select2-container--default"
                                            dir="ltr"
                                            style={{ width: '100%' }}
                                          >
                                            <span className="selection">
                                              <span
                                                className="select2-selection select2-selection--single"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                tabIndex="0"
                                                aria-label="Country / Region"
                                                role="combobox"
                                              >
                                                <span
                                                  className="select2-selection__rendered"
                                                  id="select2-shipping_country-container"
                                                  role="textbox"
                                                  aria-readonly="true"
                                                  title="United States (US)"
                                                >
                                                  United States (US)
                                                </span>
                                                <span
                                                  className="select2-selection__arrow"
                                                  role="presentation"
                                                >
                                                  <b role="presentation"></b>
                                                </span>
                                              </span>
                                            </span>
                                            <span
                                              className="dropdown-wrapper"
                                              aria-hidden="true"
                                            ></span>
                                          </span>
                                          <noscript>
                                            <button
                                              type="submit"
                                              name="woocommerce_checkout_update_totals"
                                              value="Update country / region"
                                            >
                                              Update country / region
                                            </button>
                                          </noscript>
                                        </span>
                                      </p>
                                      <p
                                        className="form-row address-field validate-required form-row-wide"
                                        id="shipping_address_1_field"
                                        data-priority="50"
                                      >
                                        <label
                                          htmlFor="shipping_address_1"
                                          className=""
                                        >
                                          Street address&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_address_1"
                                            id="shipping_address_1"
                                            placeholder="House number and street name"
                                            value="Gatlinburg, TN 37738, Gatlinburg, TN 37738"
                                            autoComplete="address-line1"
                                            data-placeholder="House number and street name"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row address-field form-row-wide"
                                        id="shipping_address_2_field"
                                        data-priority="60"
                                      >
                                        <label
                                          htmlFor="shipping_address_2"
                                          className="screen-reader-text"
                                        >
                                          Apartment, suite, unit, etc.&nbsp;
                                          <span className="optional">
                                            (optional)
                                          </span>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_address_2"
                                            id="shipping_address_2"
                                            placeholder="Apartment, suite, unit, etc. (optional)"
                                            value=""
                                            autoComplete="address-line2"
                                            data-placeholder="Apartment, suite, unit, etc. (optional)"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row address-field validate-required form-row-wide"
                                        id="shipping_city_field"
                                        data-priority="70"
                                        data-o_className="form-row form-row-wide address-field validate-required"
                                      >
                                        <label
                                          htmlFor="shipping_city"
                                          className=""
                                        >
                                          Town / City&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_city"
                                            id="shipping_city"
                                            placeholder=""
                                            value="Gatlinburg"
                                            autoComplete="address-level2"
                                          />
                                        </span>
                                      </p>
                                      <p
                                        className="form-row address-field validate-required validate-state form-row-wide"
                                        id="shipping_state_field"
                                        data-priority="80"
                                        data-o_className="form-row form-row-wide address-field validate-required validate-state"
                                      >
                                        <label
                                          htmlFor="shipping_state"
                                          className=""
                                        >
                                          State&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <select
                                            name="shipping_state"
                                            id="shipping_state"
                                            className="state_select select2-hidden-accessible"
                                            autoComplete="address-level1"
                                            data-placeholder="Select an option…"
                                            data-input-classes=""
                                            data-label="State"
                                            tabIndex="-1"
                                            aria-hidden="true"
                                          >
                                            <option value="">
                                              Select an option…
                                            </option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">
                                              California
                                            </option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">
                                              Connecticut
                                            </option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">
                                              District Of Columbia
                                            </option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">
                                              Louisiana
                                            </option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">
                                              Massachusetts
                                            </option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">
                                              Minnesota
                                            </option>
                                            <option value="MS">
                                              Mississippi
                                            </option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">
                                              New Hampshire
                                            </option>
                                            <option value="NJ">
                                              New Jersey
                                            </option>
                                            <option value="NM">
                                              New Mexico
                                            </option>
                                            <option value="NY">New York</option>
                                            <option value="NC">
                                              North Carolina
                                            </option>
                                            <option value="ND">
                                              North Dakota
                                            </option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">
                                              Pennsylvania
                                            </option>
                                            <option value="RI">
                                              Rhode Island
                                            </option>
                                            <option value="SC">
                                              South Carolina
                                            </option>
                                            <option value="SD">
                                              South Dakota
                                            </option>
                                            <option value="TN">
                                              Tennessee
                                            </option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">
                                              Washington
                                            </option>
                                            <option value="WV">
                                              West Virginia
                                            </option>
                                            <option value="WI">
                                              Wisconsin
                                            </option>
                                            <option value="WY">Wyoming</option>
                                            <option value="AA">
                                              Armed Forces (AA)
                                            </option>
                                            <option value="AE">
                                              Armed Forces (AE)
                                            </option>
                                            <option value="AP">
                                              Armed Forces (AP)
                                            </option>
                                          </select>
                                          <span
                                            className="select2 select2-container select2-container--default"
                                            dir="ltr"
                                            style={{ width: '100%' }}
                                          >
                                            <span className="selection">
                                              <span
                                                className="select2-selection select2-selection--single"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                tabIndex="0"
                                                aria-label="State"
                                                role="combobox"
                                              >
                                                <span
                                                  className="select2-selection__rendered"
                                                  id="select2-shipping_state-container"
                                                  role="textbox"
                                                  aria-readonly="true"
                                                  title="Tennessee"
                                                >
                                                  Tennessee
                                                </span>
                                                <span
                                                  className="select2-selection__arrow"
                                                  role="presentation"
                                                >
                                                  <b role="presentation"></b>
                                                </span>
                                              </span>
                                            </span>
                                            <span
                                              className="dropdown-wrapper"
                                              aria-hidden="true"
                                            ></span>
                                          </span>
                                        </span>
                                      </p>
                                      <p
                                        className="form-row address-field validate-required validate-postcode form-row-wide"
                                        id="shipping_postcode_field"
                                        data-priority="90"
                                        data-o_className="form-row form-row-wide address-field validate-required validate-postcode"
                                      >
                                        <label
                                          htmlFor="shipping_postcode"
                                          className=""
                                        >
                                          ZIP Code&nbsp;
                                          <abbr
                                            className="required"
                                            title="required"
                                          >
                                            *
                                          </abbr>
                                        </label>
                                        <span className="woocommerce-input-wrapper">
                                          <input
                                            type="text"
                                            className="input-text "
                                            name="shipping_postcode"
                                            id="shipping_postcode"
                                            placeholder=""
                                            value="37738"
                                            autoComplete="postal-code"
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="woocommerce-additional-fields">
                                  <div className="woocommerce-additional-fields__field-wrapper">
                                    <p
                                      className="form-row notes"
                                      id="order_comments_field"
                                      data-priority=""
                                    >
                                      <label
                                        htmlFor="order_comments"
                                        className=""
                                      >
                                        Order notes&nbsp;
                                        <span className="optional">
                                          (optional)
                                        </span>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <textarea
                                          name="order_comments"
                                          className="input-text "
                                          id="order_comments"
                                          placeholder="Notes about your order, e.g. special notes for delivery."
                                          rows="2"
                                          cols="5"
                                        ></textarea>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h2
                              id="order_review_heading"
                              className="text-white mt-2"
                            >
                              Your order
                            </h2>
                            <div
                              id="order_review"
                              className="woocommerce-checkout-review-order"
                            >
                              <table className="shop_table woocommerce-checkout-review-order-table woo-chart">
                                <thead>
                                  <tr>
                                    <th className="product-name">Product</th>
                                    <th className="product-total text-center">
                                      Price
                                    </th>

                                    <th className="product-total text-end">
                                      Subtotal
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {orderData?.order_items?.map(
                                    (item, index) => (
                                      <tr className="cart_item" key={index}>
                                        <td className="product-name">
                                          {item?.holder?.name}&nbsp;
                                          <strong className="product-quantity">
                                            ×&nbsp; {item?.quantity}
                                          </strong>
                                        </td>
                                        <td className="product-total text-center">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                {item?.holder?.priceFormat}
                                              </span>
                                            </bdi>
                                          </span>
                                        </td>
                                        <td className="product-total text-end">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                {getPriceFormatByType(
                                                  item?.order_totals,
                                                  'ITEM_BASE_TOTAL'
                                                )}
                                                <br />
                                                {item?.fee_to_customer === 0 &&
                                                  '( Service Fee Included )'}
                                              </span>
                                            </bdi>
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr className="cart-subtotal">
                                    <th></th>
                                    <th className="text-center">
                                      Subtotal
                                      <br />
                                      {orderData?.cart?.cart
                                        ?.fee_to_subtotal_text && (
                                        <>
                                          ({' '}
                                          {
                                            orderData?.cart?.cart
                                              ?.fee_to_subtotal_text
                                          }{' '}
                                          )
                                        </>
                                      )}
                                    </th>
                                    <td className="text-end">
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            {
                                              orderData?.cart?.cart?.totals
                                                ?.base_total_format
                                            }
                                          </span>
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="cart-subtotal">
                                    <th></th>
                                    <th className="text-center">Fee</th>
                                    <td className="text-end">
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            {
                                              orderData?.cart?.cart?.totals
                                                ?.total_fee_to_show_format
                                            }
                                          </span>
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>
                                  {orderData?.cart?.cart?.totals?.discount !==
                                    0 && (
                                    <tr className="cart-subtotal">
                                      <th></th>
                                      <th className="text-center">Discount</th>
                                      <td className="text-end">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {
                                                orderData?.cart?.cart?.totals
                                                  ?.discount_format
                                              }
                                            </span>
                                          </bdi>
                                        </span>
                                      </td>
                                    </tr>
                                  )}

                                  <tr className="order-total">
                                    <th></th>
                                    <th className="text-center">Total</th>
                                    <td className="text-end">
                                      <strong>
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {
                                                orderData?.cart?.cart?.totals
                                                  ?.total_after_discount_format
                                              }
                                            </span>
                                          </bdi>
                                        </span>
                                      </strong>{' '}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <div
                                id="payment"
                                className="woocommerce-checkout-payment mb-0"
                              >
                                <h4
                                  id="order_review_heading"
                                  className="text-white"
                                >
                                  Payment Method
                                </h4>
                                {!isLoading &&
                                  paymentMethodList?.length > 0 && (
                                    <ul className="wc_payment_methods payment_methods methods met-hods ">
                                      {/* <li className="wc_payment_method payment_method_bacs">
                                        <input
                                          id="payment_method_bacs"
                                          type="radio"
                                          className="input-radio"
                                          name="payment_method"
                                          value="bacs"
                                          checked="checked"
                                          data-order_button_text=""
                                        />
                                        <label htmlFor="payment_method_bacs">
                                          Direct bank transfer{' '}
                                        </label>
                                        <div className="payment_box payment_method_bacs">
                                          <p>
                                            Make your payment directly into our
                                            bank account. Please use your Order
                                            ID as the payment reference. Your
                                            order will not be shipped until the
                                            funds have cleared in our account.
                                          </p>
                                        </div>
                                      </li>
                                      <li className="wc_payment_method payment_method_cheque">
                                        <input
                                          id="payment_method_cheque"
                                          type="radio"
                                          className="input-radio"
                                          name="payment_method"
                                          value="cheque"
                                          data-order_button_text=""
                                        />
                                        <label htmlFor="payment_method_cheque">
                                          Check payments{' '}
                                        </label>
                                        <div
                                          className="payment_box payment_method_cheque"
                                          style={{ display: 'none' }}
                                        >
                                          <p>
                                            Please send a check to Store Name,
                                            Store Street, Store Town, Store
                                            State / County, Store Postcode.
                                          </p>
                                        </div>
                                      </li>
                                      <li className="wc_payment_method payment_method_paypal">
                                        <input
                                          id="payment_method_paypal"
                                          type="radio"
                                          className="input-radio"
                                          name="payment_method"
                                          value="paypal"
                                          data-order_button_text="Proceed to PayPal"
                                        />
                                        <label htmlFor="payment_method_paypal">
                                          PayPal{' '}
                                          <img
                                            src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg"
                                            alt="PayPal acceptance mark"
                                          />
                                          <Link
                                            to="https://www.paypal.com/us/webapps/mpp/paypal-popup"
                                            className="about_paypal"
                                            onclick="javascript:window.open('https://www.paypal.com/us/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"
                                          >
                                            What is PayPal?
                                          </Link>{' '}
                                        </label>
                                        <div
                                          className="payment_box payment_method_paypal"
                                          style={{ display: 'none' }}
                                        >
                                          <p>
                                            Pay via PayPal; you can pay with
                                            your credit card if you don’t have a
                                            PayPal account.
                                          </p>
                                        </div>
                                      </li> */}

                                      {paymentMethodList?.map((item, index) => (
                                        <li
                                          className="wc_payment_method payment_method_paypal "
                                          key={index}
                                        >
                                          <input
                                            id={item?.details?.title}
                                            type="radio"
                                            className="input-radio d-none"
                                            name="payment_method"
                                            value={item?.identifier}
                                            data-order_button_text={`Proceed to ${item?.identifier}`}
                                            onChange={(event) =>
                                              handleChange(event, item)
                                            }
                                            checked={
                                              selectedPaymentMethod ===
                                              item?.identifier
                                            }
                                          />

                                          <label htmlFor={item?.details?.title}>
                                            {item?.details?.title}
                                            <img
                                              className="d-none"
                                              src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg"
                                              alt={item?.details?.title}
                                            />
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                <div className="form-row place-order">
                                  {/* {selectedPaymentMethod === 'STRIPE' && (
                                    <StripeCheckout
                                      stripeData={
                                        selectedPaymentMethodObject?.details
                                      }
                                    />
                                  )} */}
                                  <noscript>
                                    Since your browser does not support
                                    JavaScript, or it is disabled, please ensure
                                    you click the <em>Update Totals</em> button
                                    before placing your order. You may be
                                    charged more than the amount stated above if
                                    you fail to do so. <br />
                                    <button
                                      type="submit"
                                      className="button alt"
                                      name="woocommerce_checkout_update_totals"
                                      value="Update totals"
                                    >
                                      Update totals
                                    </button>
                                  </noscript>
                                  <div className="woocommerce-terms-and-conditions-wrapper">
                                    <div className="woocommerce-privacy-policy-text"></div>
                                  </div>
                                  {/* <Link
                                    to="/thank-you-check-out"
                                    className="button alt"
                                    name="woocommerce_checkout_place_order"
                                    id="place_order"
                                    value="Place order"
                                    data-value="Place order"
                                  >
                                    Place order
                                  </Link> */}
                                  <input
                                    type="hidden"
                                    id="woocommerce-process-checkout-nonce"
                                    name="woocommerce-process-checkout-nonce"
                                    value="d1fde18dd1"
                                  />
                                  <input
                                    type="hidden"
                                    name="_wp_http_referer"
                                    value="/?wc-ajax=update_order_review"
                                  />{' '}
                                </div>
                              </div>
                            </div>
                          </form>
                          {selectedPaymentMethod === 'STRIPE' && (
                            <StripeCheckout
                              stripeData={selectedPaymentMethodObject}
                            />
                          )}
                          {selectedPaymentMethod === 'PAY_CASH' && (
                            <>
                              <Link
                                type="button"
                                onClick={handleSubmit}
                                disabled={isProcessing}
                                className="button alt"
                              >
                                {isProcessing ? 'Loading...' : 'Place order'}
                              </Link>
                              {error && <div className="error">{error}</div>}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckOut;
