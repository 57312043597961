export const API_BASE_URL = process.env.REACT_APP_API_URL + '/api/v1/';

export const LOGIN_ENDPOINT = `${API_BASE_URL}login`;
export const SIGNUP_STEP_ONE_ENDPOINT = `${API_BASE_URL}auth/login/step/one`;
export const SIGNUP_STEP_TWO_ENDPOINT = `${API_BASE_URL}auth/login/step/two`;
export const FORGOT_ENDPOINT = `${API_BASE_URL}password/forgot`;
export const RESET_ENDPOINT = `${API_BASE_URL}password/reset`;
export const UPDATE_PROFILE_ENDPOINT = `${API_BASE_URL}profile/update`;
export const ORGANIZATION_ENDPOINT = `${API_BASE_URL}organizations`;

export const EVENTS_ENDPOINT = `${API_BASE_URL}front/events`;
export const EVENT_TICKET_ENDPOINT = `${API_BASE_URL}front/event/`;
export const ORGANIZATION_FRONT_ENDPOINT = `${API_BASE_URL}front/organization`;

export const UPLOAD_ENDPOINT = `${API_BASE_URL}file/upload`;
export const REMOVE_ENDPOINT = `${API_BASE_URL}file/remove/`;

//Cart
export const SAVE_CART_ENDPOINT = `${API_BASE_URL}customer/cart/save`;
export const CART_LIST_ENDPOINT = `${API_BASE_URL}customer/cart`;
export const CART_REMOVE_ENDPOINT = `${API_BASE_URL}customer/cart/remove`;
export const CART_CLEAR_ENDPOINT = `${API_BASE_URL}customer/cart/clear`;
export const CHECKOUT_PROCEED_ENDPOINT = `${API_BASE_URL}customer/checkout/proceed`;
export const PROMO_CODE_APPLY_ENDPOINT = `${API_BASE_URL}customer/checkout/promo-code/apply`;
export const PROMO_CODE_REMOVE_ENDPOINT = `${API_BASE_URL}customer/checkout/promo-code/remove`;
export const PAYMENT_METHOD_ENDPOINT = `${API_BASE_URL}payment-methods`;
export const CHECKOUT_PAYMENT_INIT_ENDPOINT = `${API_BASE_URL}customer/checkout/payment/init/`;
export const CHECKOUT_PAYMENT_CONFIRM_ENDPOINT = `${API_BASE_URL}customer/checkout/payment/confirm/`;
export const ORDER_LIST_ENDPOINT = `${API_BASE_URL}customer/order/list`;
export const ORDER_VIEW_ENDPOINT = `${API_BASE_URL}order/view/`;
export const ORDER_TICKET_PDF_ENDPOINT = `${API_BASE_URL}order/ticket/generate/`;

export const ENTITY_VIEW_ENDPOINT = `${API_BASE_URL}entity/view`;
