import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { useLocation } from 'react-router-dom';
import {
  getModeText,
  getPriceFormatByType,
  isDiscountZero,
} from '../../../../Utils/common';

const ThankYouCheckOut = () => {
  const location = useLocation();
  const order = location.state && location.state.order;

  return (
    <>
      <div className="edgtf-woocommerce-page woocommerce-order-received edgtf-predefined-text-skin edgtf-header-standard edgtf-light-header edgtf-side-menu-slide-from-right edgtf-sticky-up-mobile-header edgtf-sticky-header-appear">
        <div className="edgtf-wrapper">
          <div className="edgtf-wrapper-inner">
            <Header />

            {/* html new add start */}

            <div className="edgtf-content">
              <div className="edgtf-content-inner">
                <div className="edgtf-container edgtf-default-page-template">
                  <div className="edgtf-container-inner clearfix pt-2">
                    <div className="edgtf-grid-row">
                      <div className="edgtf-page-content-holder edgtf-grid-col-12">
                        <div className="woocommerce">
                          <div className="woocommerce-order">
                            <h2 className="woocommerce-order-details__title text-white">
                              Thank you. Your order has been received.
                            </h2>
                            <ul className="woocommerce-order-overview woocommerce-thankyou-order-details order_details">
                              <li className="woocommerce-order-overview__order order">
                                Order number:{' '}
                                <strong>{order?.order_number}</strong>
                              </li>

                              <li className="woocommerce-order-overview__date date ">
                                Date:{' '}
                                <strong className="text-uppercase">
                                  {order?.created_at}
                                </strong>
                              </li>

                              <li className="woocommerce-order-overview__total total">
                                Total:{'  '}
                                <strong>
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        {getPriceFormatByType(
                                          order?.order_totals,
                                          'TOTAL_AFTER_DISCOUNT'
                                        )}
                                      </span>
                                    </bdi>
                                  </span>
                                </strong>
                              </li>

                              <li className="woocommerce-order-overview__payment-method method">
                                Payment method:{' '}
                                <strong>
                                  {getModeText(order?.transactions)}
                                </strong>
                              </li>
                            </ul>
                            <section className="woocommerce-order-details">
                              <h2 className="woocommerce-order-details__title text-white">
                                Order details
                              </h2>
                              <table className="woocommerce-table woocommerce-table--order-details shop_table order_details">
                                <thead>
                                  <tr>
                                    <th className="product-name">Product</th>
                                    <th className="product-total text-center">
                                      Price
                                    </th>

                                    <th className="product-total text-end">
                                      Subtotal
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order?.order_items?.map((item, index) => (
                                    <tr className="cart_item" key={index}>
                                      <td className="product-name">
                                        {item?.holder?.name}&nbsp;
                                        <strong className="product-quantity">
                                          ×&nbsp; {item?.quantity}
                                        </strong>
                                      </td>
                                      <td className="product-total text-center">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {item?.holder?.priceFormat}
                                            </span>
                                          </bdi>
                                        </span>
                                      </td>
                                      <td className="product-total text-end">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {getPriceFormatByType(
                                                item?.order_totals,
                                                'ITEM_BASE_TOTAL'
                                              )}
                                              <br />
                                              {item?.fee_to_customer === 0 &&
                                                '( Service Fee Included )'}
                                            </span>
                                          </bdi>
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot>
                                  <tr className="cart-subtotal">
                                    <th></th>
                                    <th className="text-center">
                                      Subtotal
                                      <br />
                                      {order?.feeToSubTotalText && (
                                        <>( {order?.feeToSubTotalText} )</>
                                      )}
                                    </th>
                                    <td className="text-end">
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            {
                                              order?.cart?.cart?.totals
                                                ?.base_total_format
                                            }
                                            {getPriceFormatByType(
                                              order?.order_totals,
                                              'BASE_TOTAL'
                                            )}
                                          </span>
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="cart-subtotal">
                                    <th></th>
                                    <th className="text-center">Fee</th>
                                    <td className="text-end">
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            {
                                              order?.cart?.cart?.totals
                                                ?.total_fee_to_show_format
                                            }
                                            {getPriceFormatByType(
                                              order?.order_totals,
                                              'TOTAL_FEE_TO_SHOW'
                                            )}
                                          </span>
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>
                                  {!isDiscountZero(order?.order_totals) && (
                                    <tr className="cart-subtotal">
                                      <th></th>
                                      <th className="text-center">Discount</th>
                                      <td className="text-end">
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {getPriceFormatByType(
                                                order?.order_totals,
                                                'DISCOUNT'
                                              )}
                                            </span>
                                          </bdi>
                                        </span>
                                      </td>
                                    </tr>
                                  )}

                                  <tr className="order-total">
                                    <th></th>
                                    <th className="text-center">Total</th>
                                    <td className="text-end">
                                      <strong>
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              {getPriceFormatByType(
                                                order?.order_totals,
                                                'TOTAL_AFTER_DISCOUNT'
                                              )}
                                            </span>
                                          </bdi>
                                        </span>
                                      </strong>{' '}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* html new add end */}

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouCheckOut;
