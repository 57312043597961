import { useParams } from 'react-router-dom';
import { decrypt, splitString } from '../../../Utils/common';
import OrgEvent from './Events/OrgEvent';
import NotFound from '../../../Components/NotFound';
import EventPreview from './Events/EventPreview';
import axiosInstance from '../../../Utils/axiosInstance';
import Logger from '../../../Utils/Logger';
import { EVENT_CLASS, ORGANIZATION_CLASS } from '../../../Utils/ModelClasses';
import { useEffect, useState } from 'react';
import { ENTITY_VIEW_ENDPOINT } from '../../../Utils/Endpoints';

const Preview = () => {
  const { id } = useParams();
  const decryptData = decrypt(id);
  const data = splitString(decryptData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data) {
      postView();
    }
  }, []);

  const postView = async () => {
    try {
      setIsLoading(true);
      const requestObject = {
        owner_class: data?.type === 'ORG' ? ORGANIZATION_CLASS : EVENT_CLASS,
        owner_id: data?.key,
      };
      const response = await axiosInstance.post(
        ENTITY_VIEW_ENDPOINT,
        requestObject
      );
      if (response.data.code === 200) {
        Logger.log(response.data.message, isLoading);
      }
    } catch (error) {
      Logger.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {data?.type === 'ORG' && <OrgEvent orgId={data?.key} />}
      {(data?.type === 'EVENT' || data?.type === 'VIEW') && (
        <EventPreview type={data?.type} eventId={data?.key} />
      )}
      {(!data ||
        data?.key === '' ||
        !['EVENT', 'ORG', 'VIEW'].includes(data?.type)) && <NotFound />}
    </div>
    // <div>
    //   {data?.type === 'ORG' && <OrgEvent orgId={data?.key} />}
    //   {(data?.type === 'EVENT' || data?.type === 'VIEW') && (
    //     <EventPreview type={data?.type} eventId={data?.key} />
    //   )}
    //   {console.log(data)}
    //   {!(
    //     data?.key !== '' &&
    //     (data?.type === 'EVENT' ||
    //       data?.type === 'ORG' ||
    //       data?.type === 'VIEW')
    //   ) && <NotFound />}
    // </div>
  );
};

export default Preview;
