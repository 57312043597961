import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getModeText,
  getPriceFormatByType,
  isDiscountZero,
} from '../../../../../Utils/common';

const OrderDetails = ({ order }) => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content modal-ccc">
            <div className="modal-header">
              <h5 className="modal-title d-none" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0 ">
              <div className="order-details-modal">
                <div className="order-id-new">
                  <div className="order-logo-details">
                    <div className="logo-dd">
                      <Link to="#">
                        <img src="../images/emblem-logo.png" alt="" />
                      </Link>
                    </div>
                    <div className="details-order">
                      <div className="order-id">
                        <h5 className="main-heading">Order ID</h5>
                        <p className="text-value-details">
                          #{order?.order_number}
                        </p>
                      </div>
                      <div className="order-id">
                        <h5 className="main-heading"> Order Date </h5>
                        <p className="text-value-details">
                          {order?.created_at}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="event-name-new">
                    <h5 className="main-heading heading-center">
                      Event Details
                    </h5>
                    <div className="event-all-point">
                      <p className="text-label-details">
                        Event Name:
                        <span className="text-value-details">
                          {order?.event?.name}
                        </span>
                      </p>
                      <p className="text-label-details">
                        Start Date & Time:{' '}
                        <span className="text-value-details text-uppercase">
                          {order?.event?.startDateTimeFormat}
                        </span>
                      </p>
                      <p className="text-label-details">
                        End Date & Time:{' '}
                        <span className="text-value-details text-uppercase">
                          {order?.event?.endDateTimeFormat}
                        </span>
                      </p>
                      <p className="text-label-details">
                        Location:{' '}
                        <span className="text-value-details">Center Park</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="payment-ticket">
                  <div className="payment-mode">
                    <h5 className="main-heading heading-center">
                      Payment Details
                    </h5>
                    <div className="event-all-point">
                      <p className="text-label-details">
                        Transaction Mode:{' '}
                        <span className="text-value-details">
                          {getModeText(order?.transactions)}
                        </span>
                      </p>
                      <p className="text-label-details">
                        Subtotal:{' '}
                        <span className="text-value-details">
                          {getPriceFormatByType(
                            order?.order_totals,
                            'BASE_TOTAL'
                          )}
                        </span>
                      </p>
                      <p className="text-label-details">
                        Total Fee:{' '}
                        <span className="text-value-details">
                          {getPriceFormatByType(
                            order?.order_totals,
                            'TOTAL_FEE'
                          )}
                        </span>
                      </p>
                      {!isDiscountZero(order?.order_totals) && (
                        <p className="text-label-details">
                          Discount:{' '}
                          <span className="text-value-details">
                            {getPriceFormatByType(
                              order?.order_totals,
                              'DISCOUNT'
                            )}
                          </span>
                        </p>
                      )}
                      <p className="text-label-details">
                        Total:{' '}
                        <span className="text-value-details">
                          {getPriceFormatByType(
                            order?.order_totals,
                            'TOTAL_AFTER_DISCOUNT'
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="payment-mode p-m-b">
                    <h5 className="main-heading heading-center">
                      Ticket Details
                    </h5>
                    <div className="event-all-point">
                      <p className="text-label-details">
                        Ticket Number:{' '}
                        <span className="text-value-details">#A12345</span>
                      </p>
                      <p className="text-label-details">
                        Quantity: <span className="text-value-details">2</span>
                      </p>
                      <p className="text-label-details">
                        Price: <span className="text-value-details">$50</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-none">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object,
};

export default OrderDetails;
