import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from '../Components/NotFound';
import { publicRoutes } from './PublicRoutes';
import PublicRoute from '../Utils/Customer/PublicRoute';
import PrivateRoute from '../Utils/Customer/PrivateRoute';
import { privateRoutes } from './PrivateRoutes';
import { ToastContainer } from 'react-toastify';
import Main from '../Customer/Pages/Home/Main';
import EventPreview from '../Customer/Pages/Home/Events/EventPreview';
import Cart from '../Customer/Pages/Home/Events/Cart';
import CheckOut from '../Customer/Pages/Home/Events/CheckOut';
import ThankYouCheckOut from '../Customer/Pages/Home/Events/ThankYouCheckOut';
import ScrollToTop from './ScrollToTop';
import Preview from '../Customer/Pages/Home/Preview';
import Setting from '../Customer/Pages/Setting/Setting';
import OrgEvent from '../Customer/Pages/Home/Events/OrgEvent';
import SearchResults from '../Customer/Pages/Auth/SearchResults/SearchResults';

const AppRoutes = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      {publicRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <PublicRoute
              element={route.element}
              redirectTo={route.redirectTo}
            />
          }
        />
      ))}
      {privateRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<PrivateRoute element={route.element} />}
        />
      ))}
      <Route path="/" element={<Main />} />
      <Route path="*" element={<NotFound />} />
      <Route path="event-detail" element={<EventPreview />} />
      <Route path="/:id" element={<Preview />} />
      <Route path="cart" element={<Cart />} />
      <Route path="check-out" element={<CheckOut />} />
      <Route path="thank-you-check-out" element={<ThankYouCheckOut />} />
      <Route path="setting" element={<Setting />} />
      <Route path="org-event" element={<OrgEvent />} />
      <Route path="search" element={<SearchResults />} />
    </Routes>
    <ToastContainer />
  </Router>
);
export default AppRoutes;
